import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useForm } from "react-hook-form";
import HelmetTags from "../../../../MainComponents/HelmetTags";

const AdminAddStudentToCourse = () => {
  const { courseID } = useParams();
  const navigate = useNavigate();

  function reducer(state, action) {
    switch (action.type) {
      case "setErrors": {
        return {
          ...state,
          errors: action.payload,
        };
      }

      case "setSubmitAddStudent": {
        return {
          ...state,
          submitAddStudent: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddStudent: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  //!--------- add  student -------
  const [AddStudentSuccess, AddStudentErrors, AddStudentLoading] = useAxios(
    process.env.REACT_APP_ADMIN_ADD_STUDENT_TO_COURSE_API,
    "POST",
    state.submitAddStudent.flag,
    state.submitAddStudent.dependency,
    state.submitAddStudent.data,
    true
  );

  useEffect(() => {
    //!---add actions ----

    if (AddStudentSuccess) {
      navigate(-1);
      reset();
    }
    //! make the dependency false again to listen to the next try to submit
  }, [AddStudentSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data) => {
    dispatch({
      type: "setSubmitAddStudent",
      payload: {
        flag: "AddStudentToCourse",
        dependency: !state.submitAddStudent.dependency,
        data: {
          ...data,
          course_id: courseID,
        },
      },
    });
  };

  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Add Student | Dr.Tamer Ghonim"></HelmetTags>

      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          Please fill in the information to Add Student
        </h2>

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3xl bg-white p-16 shadow-lg shadow-blue-200/50"
        >
          {/** Name stage filed */}
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            <div className="name flex w-full flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="name">username</label>
              <input
                className="signin-inputs w-full pl-4"
                type="text"
                id="username"
                placeholder="username"
                name="username"
                autoComplete="on"
                {...register("username", {
                  required: true,
                  maxLength: 40,
                  minLength: 3,
                })}
              />
              {errors.username && (
                <p className="w-full  text-[12px] text-blue-900  ">
                  {errors.username.type === "required" &&
                    "Please fill out this field"}
                  {errors.username.type === "maxLength" &&
                    "The maximum number of characters is 40 characters"}
                  {errors.username.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}
              {
                //!-------server errors -----

                AddStudentErrors &&
                  AddStudentErrors?.response?.data?.errors?.username && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {AddStudentErrors?.response?.data?.errors?.username[0]}
                    </p>
                  )
              }
            </div>
          </div>

          {/** submit */}
          <button
            disabled={
              !isValid || (AddStudentLoading && state.submitAddStudent.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {AddStudentLoading && state.submitAddStudent.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>Add Student</p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
};

export default AdminAddStudentToCourse;
