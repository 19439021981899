import { useReducer } from "react";
import useAxios from "../../../MainComponents/Hooks/useAxios.jsx";
import {
  faArrowRightFromBracket,
  faCircleChevronRight,
  faSchool,
  faUsers,
  faUser,
  faFileAlt,
  faSignsPost,
  faPeopleArrows,
  faPeopleGroup,
  faImage,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import {
  useAuthDispatch,
  useAuthState,
} from "../../../MainComponents/GlobalContext";

function AdminLayout() {
  const AuthDispatch = useAuthDispatch();
  const AuthState = useAuthState();

  const navigate = useNavigate();
  const initialState = {
    collapse: false,
    showSidebar: false,
    refetch: false,
  };
  function reducer(state, action) {
    switch (action.type) {
      case "SetCollapse": {
        return {
          ...state,
          collapse: !state.collapse,
        };
      }
      case "SetRefetch": {
        return {
          ...state,
          refetch: !state.refetch,
        };
      }
      case "SetShowSidebar": {
        return {
          ...state,
          showSidebar: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  //!--------- get numbers in tabs-------
  const [NumbersInTabs, NumbersInTabsErrors] = useAxios(
    process.env.REACT_APP_ADMIN_NUMBERS_IN_TABS_API,
    "GET",
    "GET",
    state.refetch,
    "",
    "",
    AuthState.refetchIfAcceptOrDeleteRequest
  );

  function handleSignOut() {
    dispatch({
      type: "SetShowSidebar",
      payload: false,
    });

    localStorage.removeItem("userData");
    AuthDispatch({ type: "setUserData", payload: null });
    navigate("/admin/signin");
  }

  return (
    <section className="flex flex-row-reverse h-screen max-h-screen w-full overflow-hidden bg-light dark:bg-dark dark:text-light md:pt-32">
      <section className="header-mobile-only fixed inset-0 top-0 z-50  hidden h-32 w-full items-center justify-between bg-white px-16 text-light md:flex">
        <div className="logo h-fit">
          <img
            className="h-24 cursor-pointer transition duration-200 ease-in-out hover:scale-105"
            onClick={() => navigate("/admin/students")}
            src="https://api.drtamerenglishsat.com/uploads/assets/logo.png"
            alt="logoDark"
          />
        </div>
        <div className="burger-dash bg">
          <input
            onChange={() =>
              dispatch({
                type: "SetShowSidebar",
                payload: !state.showSidebar,
              })
            }
            checked={state.showSidebar}
            type="checkbox"
            id="checkbox"
          />
          <label htmlFor="checkbox" className="toggle">
            <div className="bar bar--top"></div>
            <div className="bar bar--middle"></div>
            <div className="bar bar--bottom"></div>
          </label>
        </div>
      </section>
      {/**
       * //!outlet
       *
       */}
      <section className="outlet  h-full w-full overflow-y-auto">
        <Outlet />
      </section>
      {/**
       * //!placeholder
       *
       */}
      <div
        className={`placeholder h-full ${
          state.collapse ? "w-32" : "w-1/5 min-w-[20%]"
        }  transition-all duration-200 ease-in-out md:w-0 md:min-w-[0%]`}
      ></div>
      {/**
       * //!dash menu fixed
       *
       */}
      <div
        className={`dash-menu-fixed  z-40 ${
          state.collapse ? "w-32" : "w-1/5 "
        } fixed left-0 top-0 
                    h-full w-1/5 transition-all duration-200 ease-in-out ${
                      state.showSidebar
                        ? "md:pointer-events-auto md:w-full md:translate-x-0 md:opacity-100"
                        : "md:pointer-events-none  md:translate-x-20 md:opacity-0"
                    } md:pt-32`}
      >
        <div className="z-[99999] collapse-btn-wrapper relative w-full md:hidden ">
          <FontAwesomeIcon
            onClick={() =>
              dispatch({
                type: "SetCollapse",
              })
            }
            className={` absolute right-0 top-20 z-50  translate-x-4 rotate-180 cursor-pointer rounded-full bg-light text-5xl transition duration-200 ease-in-out hover:scale-105 ${
              state.collapse && "rotate-0"
            }`}
            icon={faCircleChevronRight}
          />
        </div>
        <div className="dash-menu-container  flex  h-full w-full   flex-col items-center justify-between gap-10 overflow-y-auto bg-gray-50 py-10">
          <div className="logo h-fit md:hidden fixed top-0 bg-gray-50 z-50 border-b-2 border-gray-100">
            <img
              className={`mt-4 ${
                state.collapse ? "h-32" : "h-48"
              }  cursor-pointer transition duration-200 ease-in-out hover:scale-105`}
              onClick={() => navigate("/admin/students")}
              src="https://api.drtamerenglishsat.com/uploads/assets/logo.png"
              alt="logo-dash-light"
            />
          </div>

          <ul className="dash-menu-items flex h-fit w-full  flex-col items-center gap-5 relative top-[100px] mt-10">
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("students")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/students"
                  className={`group flex  h-20 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "justify-bet items-center  px-5"
                  }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div
                    className={`text-icon  flex w-full items-center gap-10 ${
                      !state.collapse ? "" : "gap-10 justify-center"
                    }`}
                  >
                    <div className="icon">
                      <FontAwesomeIcon className={`w-8`} icon={faPeopleGroup} />
                    </div>
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      Students
                    </p>
                  </div>
                </NavLink>
              </li>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("est")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/est"
                  className={`group flex  h-20 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "justify-bet items-center  px-5"
                  }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div
                    className={`text-icon  flex w-full items-center gap-10 ${
                      !state.collapse ? "" : "gap-10 justify-center"
                    }`}
                  >
                    <div className="icon">
                      <FontAwesomeIcon className={`w-8`} icon={faFileAlt} />
                    </div>
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      Est
                    </p>
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("act")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/act"
                  className={`group flex  h-20 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "justify-bet items-center  px-5"
                  }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div
                    className={`text-icon  flex w-full items-center gap-10 ${
                      !state.collapse ? "" : "gap-10 justify-center"
                    }`}
                  >
                    <div className="icon">
                      <FontAwesomeIcon className={`w-8`} icon={faFileAlt} />
                    </div>
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      Act
                    </p>
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("dsat")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/sat"
                  className={`group flex  h-20 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "justify-bet items-center  px-5"
                  }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div
                    className={`text-icon  flex w-full items-center gap-10 ${
                      !state.collapse ? "" : "gap-10 justify-center"
                    }`}
                  >
                    <div className="icon">
                      <FontAwesomeIcon className={`w-8`} icon={faFileAlt} />
                    </div>
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      Digital Sat
                    </p>
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("skills")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/domains"
                  className={`group flex  h-20 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "justify-bet items-center  px-5"
                  }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div
                    className={`text-icon  flex w-full items-center gap-10 ${
                      !state.collapse ? "" : "gap-10 justify-center"
                    }`}
                  >
                    <div className="icon">
                      <FontAwesomeIcon className={`w-8`} icon={faFileAlt} />
                    </div>
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      Skills
                    </p>
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("request")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/requests"
                  className={`group flex  h-20 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "justify-bet items-center  px-5"
                  }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div
                    className={`text-icon  flex w-full items-center gap-10 ${
                      !state.collapse ? "" : "gap-10 justify-center"
                    }`}
                  >
                    <div className="icon">
                      <FontAwesomeIcon className={`w-8`} icon={faUserPlus} />
                    </div>
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      Requests
                    </p>
                  </div>
                  {NumbersInTabs?.data?.requests_count &&
                    (AuthState.userData?.admin?.roles[0]?.name ===
                      "superadmin" ||
                      AuthState.userData?.admin?.permissions?.some(
                        (permission) =>
                          permission.name.startsWith("request_accept")
                      )) && (
                      <span
                        className={`numbers flex items-center  justify-center rounded-full p-1  ${
                          state.collapse
                            ? "mb-5 h-3   w-3  bg-secondary text-[0px]"
                            : "h-10  w-10 min-w-[25px] min-h-[25px]  text-2xl"
                        }  text-secondary transition-all duration-200 ease-in-out group-hover:bg-light amd:mb-5 amd:h-3 amd:w-3  amd:min-w-[8px] amd:min-h-[8px] amd:bg-secondary amd:text-[0px] md:mb-0 md:h-10 md:w-10 md:min-w-[25px] md:min-h-[25px] md:bg-transparent md:text-2xl`}
                      >
                        {NumbersInTabs?.data?.requests_count}
                      </span>
                    )}
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("reviews")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/reviews"
                  className={`group flex  h-20 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "justify-bet items-center  px-5"
                  }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div
                    className={`text-icon  flex w-full items-center gap-10 ${
                      !state.collapse ? "" : "gap-10 justify-center"
                    }`}
                  >
                    <div className="icon">
                      <FontAwesomeIcon className={`w-8`} icon={faImage} />
                    </div>
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      Reviews
                    </p>
                  </div>
                </NavLink>
              </li>
            )}
            {AuthState.userData?.admin?.roles[0]?.name === "superadmin" && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/assistants"
                  className={`group flex  h-20 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "justify-bet items-center  px-5"
                  }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div
                    className={`text-icon  flex w-full items-center gap-10 ${
                      !state.collapse ? "" : "gap-10 justify-center"
                    }`}
                  >
                    <div className="icon">
                      <FontAwesomeIcon className={`w-8`} icon={faUsers} />
                    </div>
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      Assistants
                    </p>
                  </div>
                </NavLink>
              </li>
            )}

            <li
              onClick={() =>
                dispatch({
                  type: "SetRefetch",
                })
              }
              className="flex h-full w-full justify-center"
            >
              <NavLink
                onClick={() =>
                  dispatch({
                    type: "SetShowSidebar",
                    payload: false,
                  })
                }
                to="/admin/profile"
                className={`group flex h-20 w-9/12  alg:w-11/12 ${
                  state.collapse
                    ? "items-center justify-center px-0"
                    : "items-center justify-between px-5"
                }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
              >
                <div
                  className={`text-icon  flex w-full items-center gap-10 ${
                    !state.collapse ? "" : "gap-10 justify-center"
                  }`}
                >
                  <div className="icon">
                    <FontAwesomeIcon className="w-8" icon={faUser} />
                  </div>
                  <p
                    className={` ${
                      state.collapse ? "hidden" : " inline-block"
                    }`}
                  >
                    Profile{" "}
                  </p>
                </div>
              </NavLink>
            </li>
            <li className="log-out flex h-fit  w-full justify-center mb-10">
              <button
                onClick={() => handleSignOut()}
                className={`group flex h-20 w-9/12  alg:w-11/12 ${
                  state.collapse
                    ? "items-center justify-center px-0"
                    : "items-center justify-between px-5"
                }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
              >
                <div
                  className={`text-icon  flex w-full items-center gap-10 ${
                    !state.collapse ? "" : "gap-10 justify-center"
                  }`}
                >
                  <div className="icon">
                    <FontAwesomeIcon
                      className="w-8 rotate-180"
                      icon={faArrowRightFromBracket}
                    />
                  </div>
                  <p
                    className={` ${
                      state.collapse ? "hidden" : " inline-block"
                    } `}
                  >
                    Logout{" "}
                  </p>
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default AdminLayout;
