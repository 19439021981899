import {
  faChevronUp,
  faDownload,
  faFileLines,
  faFilePdf,
  faFilePen,
  faFileSignature,
  faInfinity,
  faLink,
  faLock,
  faLockOpen,
  faMobile,
  faQuestion,
  faSpinner,
  faTv,
  faUnlockKeyhole,
  faUserGraduate,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useReducer, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import Loader from "../../../MainComponents/Loader.jsx";
import HelmetTags from "../../../MainComponents/HelmetTags";
import { Popconfirm } from "antd";
import SingleLectureCountDown from "./components/SingleLectureCountDown";
import VdoCiperrPlayer from "./vdoCipherComponents/VdoCiperrPlayer";
import useVdocipher from "./vdoCipherComponents/hooks/useVdocipher";
import { BunnyVideo } from "./components/BunnyVideo.jsx";

function StudentLecture({ landing }) {
  const { courseID, sessionID } = useParams();
  const [videoRef, setVideoRef] = useState(null);
  const videoContainerRef = useRef();
  const { loadVideo, isAPIReady } = useVdocipher();
  const navigate = useNavigate();

  function reducer(state, action) {
    switch (action.type) {
      case "setCollapse": {
        return {
          ...state,
          collapse: action.payload,
        };
      }
      case "setSrc": {
        return {
          ...state,
          src: action.payload,
        };
      }
      case "setCurrentVideo": {
        return {
          ...state,
          currentVideo: action.payload,
        };
      }
      case "setPlatform": {
        return {
          ...state,
          platform: action.payload,
        };
      }
      case "setBuyLecture": {
        return {
          ...state,
          buyLecture: action.payload,
        };
      }
      case "setBuyLectureByPoints": {
        return {
          ...state,
          buyLectureByPoints: action.payload,
        };
      }
      case "setBuyLectureByAmount": {
        return {
          ...state,
          buyLectureByAmount: action.payload,
        };
      }
      case "setRefetch": {
        return {
          ...state,
          refetch: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    collapse: false,
    src: "",
    refetch: false,
    currentVideo: null,
    buyLecture: {
      flag: "",
      dependency: false,
      data: {},
    },
    buyLectureByPoints: {
      flag: "",
      dependency: false,
      data: {},
    },
    buyLectureByAmount: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  //!---- get lecture info -------
  const api = landing
    ? process.env.REACT_APP_VISITER_COURSES_SESSION_INFO_API
    : process.env.REACT_APP_STUDENT_COURSES_SESSION_INFO_API;

  const [LectureInfoSuccess, LectureInfoErrors, LectureInfoLoading] = useAxios(
    `${api}/${sessionID}`,
    "GET",
    courseID,
    state.refetch
  );

  //!---- buy lecture  -------
  const [buyLectureSuccess, buyLectureErrors, submitLoading] = useAxios(
    process.env.REACT_APP_STUDENT_BUY_LECTURE_API,
    "POST",
    state.buyLecture.flag,
    state.buyLecture.dependency,
    state.buyLecture.data,
    true
  );
  //!---- buy lecture By Points -------
  const [
    buyLectureByPointsSuccess,
    buyLectureByPointsErrors,
    buyLectureByPointsLoading,
  ] = useAxios(
    process.env.REACT_APP_STUDENT_BUY_BY_POINTS_API,
    "POST",
    state.buyLectureByPoints.flag,
    state.buyLectureByPoints.dependency,
    state.buyLectureByPoints.data,
    true
  );
  // !---- buy lecture By amount -------
  const [
    buyLectureByAmountSuccess,
    buyLectureByAmountErrors,
    buyLectureByAmountLoading,
  ] = useAxios(
    process.env.REACT_APP_STUDENT_BUY_BY_AMOUNT_API,
    "POST",
    state.buyLectureByAmount.flag,
    state.buyLectureByAmount.dependency,
    state.buyLectureByAmount.data,
    true
  );

  //!---refetch the lecture data again if the code is correct to open the paid content

  useEffect(() => {
    if (
      buyLectureSuccess ||
      buyLectureByPointsSuccess ||
      buyLectureByAmountSuccess
    ) {
      dispatch({
        type: "setRefetch",
        payload: !state.refetch,
      });
      reset();
    }
  }, [buyLectureByPointsSuccess, buyLectureSuccess, buyLectureByAmountSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onTouched",
  });

  const handleBuyLecture = (data) => {
    dispatch({
      type: "setBuyLecture",
      payload: {
        flag: "buyLecture",
        dependency: !state.buyLecture.dependency,
        data: { ...data, id: courseID },
      },
    });
  };
  const handleBuyLectureByPoints = () => {
    dispatch({
      type: "setBuyLectureByPoints",
      payload: {
        flag: "buyLectureByPoints",
        dependency: !state.buyLectureByPoints.dependency,
        data: { id: courseID },
      },
    });
  };
  const handleBuyLectureByAmount = () => {
    dispatch({
      type: "setBuyLectureByAmount",
      payload: {
        flag: "buyLectureByAmount",
        dependency: !state.buyLectureByAmount.dependency,
        data: { id: courseID },
      },
    });
  };
  if (LectureInfoLoading) {
    return <Loader />;
  }
  if (LectureInfoErrors && typeof LectureInfoErrors === "string") {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <p>{LectureInfoErrors}</p>
      </div>
    );
  }

  return (
    <section
      className={`width min-h-screen   py-20 dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="Lectures | Dr.Tamer Ghonim"></HelmetTags>
      {/**
       * //!---- lecture-content and buying form -----
       */}
      <div className="lecture-top flex w-full justify-between gap-20 flex-col items-center ">
        {/**
         * //!------content-----
         */}
        <div
          dir="ltr"
          className={` flex flex-col w-full items-center text-center transition-{width} duration-500  ease-in-out md:w-full`}
        >
          <div
            className={`lecture-session-img-container  relative  overflow-hidden rounded-[25px]  p-7  w-full  h-[320px] max-h-[320px]  `}
          >
            <img
              className="h-full w-full cursor-pointer rounded-[20px] object-cover duration-300 hover:saturate-150"
              src={LectureInfoSuccess?.session?.img}
              alt={LectureInfoSuccess?.session?.title}
            />
          </div>
          <div
            className={`title-description mt-8 rounded-2xl  p-5 w-full text-center`}
          >
            <h1 className="lecture-session-title mb-9 text-4xl  font-bold">
              {LectureInfoSuccess?.session?.title}
            </h1>
            <h2 className="lecture-session-description text-3xl">
              {LectureInfoSuccess?.session?.description}
            </h2>
          </div>
        </div>
      </div>
      {/**
       * //!---- lecture homework  quiz & PDFs -----
       */}
      {LectureInfoSuccess?.data?.session?.is_buy === 1 &&
        (LectureInfoSuccess?.data?.session_history?.is_hw === 1 ||
          LectureInfoSuccess?.data?.session_history?.is_quiz === 1) && (
          <section className="mt-24 flex w-full items-start justify-center gap-4 rounded-2xl border-2 border-secondary py-5 dark:bg-dark dark:text-light md:flex-col-reverse md:items-center md:justify-start">
            {/**
             * //!---- homework -----
             */}
            {LectureInfoSuccess?.data?.session_history?.is_hw === 1 && (
              <div className="homework h-96 w-1/3 border-r-2 border-r-secondary p-8 py-4 md:w-full md:border-r-0 md:border-t-2 md:border-t-secondary md:pt-10">
                <div className="mb-12 flex justify-between">
                  {LectureInfoSuccess?.data?.session_history?.is_hw_result ===
                  1 ? (
                    <Link
                      to={`/home/lectures/sessions/modalAnswer/`}
                      className=" w-fit  rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    >
                      Answer model
                    </Link>
                  ) : (
                    <Link
                      to={`/home/homework/${courseID}`}
                      className=" w-fit  rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    >
                      Start the homework
                    </Link>
                  )}

                  <h2 className=" w-fit  text-3xl font-semibold	 underline decoration-wavy underline-offset-8 ">
                    <span className="mr-4">Homework</span>
                    <FontAwesomeIcon icon={faFilePen} />
                  </h2>
                </div>

                {LectureInfoSuccess?.data?.session_history?.is_hw_result ===
                  1 && (
                  <div className="student-quiz-info flex w-full flex-col gap-10">
                    <h2 className="flex w-full items-center justify-between ">
                      <span>
                        {LectureInfoSuccess?.data?.session_history.hw_degree}
                      </span>
                      <span className="font-semibold">Degree</span>
                    </h2>
                    <h2 className="flex w-full items-center justify-between ">
                      <span>
                        {LectureInfoSuccess?.data?.session_history.averagehw}
                      </span>
                      <span className="font-semibold">Avarage Degrees</span>
                    </h2>
                    <h2 className="flex w-full items-center justify-between ">
                      <span>
                        {LectureInfoSuccess?.data?.session_history.rankhw}
                      </span>
                      <span className="font-semibold">
                        Student Rank in the lecture
                      </span>
                    </h2>
                  </div>
                )}
              </div>
            )}
            {/**
             * //!---- quiz -----
             */}
            {LectureInfoSuccess?.data?.session_history?.is_quiz === 1 && (
              <div className="quiz h-96 w-1/3 border-l-2 border-l-secondary  p-8 py-4 md:w-full md:border-r-0 md:border-t-2 md:border-t-secondary md:pt-10">
                <div className="mb-12 flex justify-between">
                  {LectureInfoSuccess?.data?.session_history?.is_quiz_result ===
                    1 &&
                  LectureInfoSuccess?.data?.session_history?.quiz_done === 1 ? (
                    <Link
                      to={`/home/lectures/sessions/modalAnswer/`}
                      className=" w-fit  rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    >
                      Answer model
                    </Link>
                  ) : (
                    <Link
                      to={`/home/quiz/${courseID}`}
                      className=" w-fit  rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    >
                      Start the exam
                    </Link>
                  )}

                  <h2 className=" w-fit  text-3xl font-semibold	 underline decoration-wavy underline-offset-8 ">
                    <span className="mr-4">Exam</span>
                    <FontAwesomeIcon icon={faFileSignature} />
                  </h2>
                </div>

                {LectureInfoSuccess?.data?.session_history?.is_quiz_result ===
                  1 &&
                  LectureInfoSuccess?.data?.session_history?.quiz_done ===
                    1 && (
                    <div className="student-quiz-info flex w-full flex-col gap-10">
                      <h2 className="flex w-full items-center justify-between ">
                        <span>
                          {
                            LectureInfoSuccess?.data?.session_history
                              .quiz_degree
                          }
                        </span>
                        <span className="font-semibold">Degree</span>
                      </h2>
                      <h2 className="flex w-full items-center justify-between ">
                        <span>
                          {LectureInfoSuccess?.data?.session_history.averageqz}
                        </span>
                        <span className="font-semibold">Avarage Degrees</span>
                      </h2>
                      <h2 className="flex w-full items-center justify-between ">
                        <span>
                          {LectureInfoSuccess?.data?.session_history.rankqz}
                        </span>
                        <span className="font-semibold">
                          Student Rank in the lecture
                        </span>
                      </h2>
                    </div>
                  )}
              </div>
            )}
          </section>
        )}
      {/**
       * //!---- lecture-bottom -----
       */}
      <div className="lecture-bottom video-playlist  mt-24 flex flex-row-reverse w-full items-start justify-between gap-24 lg:gap-16 md:flex-col-reverse md:items-center">
        <div className="w-2/3 md:w-full">
          <div
            className={`
              ${
                LectureInfoSuccess?.data?.session_history?.views === 0
                  ? "hidden"
                  : "flex"
              } video  width justify-end`}
          >
            <div
            // className={`iframe-wrapper aspect-video overflow-hidden rounded-2xl  ${
            //   state.currentVideo !== null && "border-secondary border-2 "
            // } w-full`}
            >
              {/* <VdoCiperrPlayer
                videoId={state.currentVideo}
                platform={state.platform}
                // views={state.views}
                history_id={LectureInfoSuccess?.data?.session_history?.key}
                videoRef={videoRef}
                videoContainerRef={videoContainerRef}
                isAPIReady={isAPIReady}
                setVideoRef={setVideoRef}
              /> */}
              {/* {state.platform !== "vdocipher" && state.platform !== "bunny" && (
                <iframe
                  src={state.src}
                  width="100%"
                  height="100%"
                  title="YouTube video player"
                  frameBorder="0"
                  //style={{ aspectRatio: "16 / 9" }}
                  allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  id="courseVideo"
                ></iframe>
              )} */}
              {state.src && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: state?.src,
                  }}
                />
              )}

              {/* {state.platform === "bunny" && <BunnyVideo src={state.src} />} */}
            </div>
          </div>
        </div>

        <div className="playlist w-1/3  md:w-full">
          <div
            className={`toggle-playlist w-full overflow-hidden rounded-2xl border-2  border-secondary  ${
              state.collapse ? "h-[64px] bg-secondary text-light " : "h-[465px]"
            } transition-{height} duration-500 ease-in-out `}
          >
            <div
              className="toggle-playlist  h-[64px] w-full cursor-pointer items-center justify-between gap-8 border-b-2 border-b-secondary  px-8 "
              onClick={() =>
                dispatch({
                  type: "setCollapse",
                  payload: !state.collapse,
                })
              }
            >
              <div dir="ltr" className="toggle-right font-semibold"></div>
              <div className="toggle-left flex items-center justify-between gap-5 h-full">
                <span className=" font-semibold">Session Content</span>
                <FontAwesomeIcon
                  className={`${
                    state.collapse ? "rotate-180" : ""
                  } text-3xl transition-transform duration-500 ease-in-out`}
                  icon={faChevronUp}
                />
              </div>
            </div>
            <div
              className={`playlist flex h-[400px] w-full flex-col gap-7 overflow-y-auto p-8 transition-opacity duration-500 ease-in-out ${
                state.collapse ? "opacity-0 " : "opacity-100"
              }`}
            >
              {LectureInfoSuccess?.data?.map((video, index) => {
                // let src =
                //   video.platform === "youtube"
                //     ? `https://www.youtube.com/embed/${video.vdo_id}`
                //     : video.platform === "bunny"
                //       ? `https://iframe.mediadelivery.net/embed/234526/${video.vdo_id}`
                //       : video.platform === "vimeo"
                //         ? `https://player.vimeo.com/video/${video.vdo_id}`
                //         : `https://player.vdocipher.com/v2/?otp=${video.OTP}&playbackInfo=${video.playbackInfo}`;

                return (
                  <div
                    className={`single-video w-full  ${
                      video.is_free === 1
                        ? "cursor-pointer "
                        : " cursor-not-allowed"
                    }`}
                    key={video.key}
                    onClick={() => landing && navigate("/signin")}
                  >
                    {video.type === "link" ? (
                      <div
                        className={`h-full w-full  rounded-xl p-4 transition-all duration-200 ease-in-out hover:bg-secondary hover:text-white 
                          ${
                            state.currentVideo === index &&
                            " bg-secondary text-white"
                          }`}
                      >
                        <a href={video.link} target="_blank" rel="noreferrer">
                          <div
                            dir="ltr"
                            className={`above flex w-full items-center justify-between gap-2`}
                          >
                            <div className="above-title w-session flex items-center justify-start gap-7">
                              <FontAwesomeIcon icon={faLink} />
                              <p className="text- w-11/12 truncate hover:text-clip ">
                                {index + 1}. {video.name}
                              </p>
                            </div>
                            <div className="above-icon flex w-[20px] justify-end">
                              <FontAwesomeIcon
                                icon={video.is_free === 1 ? null : faLock}
                              />
                            </div>
                          </div>
                        </a>
                      </div>
                    ) : video.type === "pdf" ? (
                      <div
                        className={`h-full w-full  rounded-xl p-4 transition-all duration-200 ease-in-out hover:bg-secondary hover:text-white 
                          ${
                            state.currentVideo === index &&
                            " bg-secondary text-white"
                          }`}
                      >
                        <a
                          href={video.link}
                          download={true}
                          // target="_blank"
                          rel="noreferrer"
                        >
                          <div
                            dir="ltr"
                            className={`above flex w-full items-center justify-between gap-2`}
                          >
                            <div className="above-title w-session flex items-center justify-start gap-7">
                              <FontAwesomeIcon icon={faFilePdf} />
                              <p className="text- w-11/12 truncate hover:text-clip ">
                                {index + 1}. {video.name}
                              </p>
                            </div>
                            <div className="above-icon flex w-[20px] justify-end">
                              <FontAwesomeIcon
                                icon={video.is_free === 1 ? null : faLock}
                              />
                            </div>
                          </div>
                        </a>
                      </div>
                    ) : video.type === "quiz" || video.type === "exam" ? (
                      <div
                        className={`h-full w-full  rounded-xl p-4 transition-all duration-200 ease-in-out hover:bg-secondary hover:text-white 
                          ${
                            state.currentVideo === index &&
                            " bg-secondary text-white"
                          }`}
                      >
                        <button
                          className={`w-full ${
                            video.is_free === 1
                              ? "cursor-pointer "
                              : " cursor-not-allowed"
                          }`}
                          disabled={video.is_free === 0}
                          onClick={() =>
                            navigate(
                              `/home/courses/sessions/solve-exam/${video.key}`
                            )
                          }
                        >
                          <div
                            dir="ltr"
                            className={`above flex w-full items-center justify-between gap-2`}
                          >
                            <div className="above-title w-session flex items-center justify-start gap-7">
                              <FontAwesomeIcon icon={faFilePen} />
                              <p className=" w-fit  truncate hover:text-clip ">
                                {index + 1}. {video.name}
                              </p>
                            </div>
                            <div className="above-icon flex w-[20px] justify-end">
                              <FontAwesomeIcon
                                icon={video.is_free === 1 ? null : faLock}
                              />
                            </div>
                          </div>
                        </button>
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          dispatch({
                            type: "setCurrentVideo",
                            payload: index,
                          });
                          dispatch({
                            type: "setSrc",
                            payload: video.link,
                          });
                          // dispatch({
                          //   type: "setPlatform",
                          //   payload: video.platform,
                          // });
                          // vdociper logic
                          // videoContainerRef.current.innerHTML = "";
                          // videoContainerRef.current.classList.add("haveVideo");
                          // const videoRef = loadVideo({
                          //   otp: video.OTP,
                          //   playbackInfo: video.playbackInfo,
                          //   container: videoContainerRef.current,
                          //   configuration: {
                          //     // autoplay: true
                          //   },
                          // });
                          // setVideoRef(videoRef);
                        }}
                        className={`h-full w-full  rounded-xl p-4 transition-all duration-200 ease-in-out hover:bg-secondary hover:text-white 
                          ${
                            state.currentVideo === index &&
                            " bg-secondary text-white"
                          }`}
                      >
                        <div
                          dir="ltr"
                          className={`above flex w-full items-center justify-between gap-2`}
                        >
                          <div className="above-title w-session flex items-center justify-start gap-7">
                            <FontAwesomeIcon icon={faVideo} />
                            <p className="text- w-11/12 truncate hover:text-clip ">
                              {index + 1}. {video.name}
                            </p>
                          </div>
                          <div className="above-icon flex w-[20px] justify-end">
                            <FontAwesomeIcon
                              icon={video.is_free === 1 ? null : faLock}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
              {/**
               * //!---- PDFs -----
               */}
              {LectureInfoSuccess?.data?.attachments?.map(
                (attachment, index) => {
                  return (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      href={
                        attachment.is_public === 1 ||
                        LectureInfoSuccess?.data?.session?.is_buy === 1
                          ? attachment.address
                          : null
                      }
                      download={attachment.name}
                      rel="noreferrer"
                      target={
                        attachment.is_public === 1 ||
                        LectureInfoSuccess?.data?.session?.is_buy === 1
                          ? "_blank"
                          : null
                      }
                      className={`single-attachment w-full  ${
                        attachment.is_public === 1 ||
                        LectureInfoSuccess?.data?.session?.is_buy === 1
                          ? "cursor-pointer "
                          : " cursor-not-allowed"
                      }`}
                      key={attachment.key}
                    >
                      <div
                        className={`h-full w-full  rounded-xl p-4 transition-all duration-200 ease-in-out ${
                          attachment.is_public === 1 ||
                          LectureInfoSuccess?.data?.session?.is_buy === 1
                            ? ""
                            : " pointer-events-none"
                        }
                          hover:bg-secondary hover:text-white`}
                      >
                        <div
                          dir="ltr"
                          className={`above flex w-full items-center justify-between gap-2`}
                        >
                          <div className="above-title w-session flex items-center justify-start gap-7">
                            <FontAwesomeIcon icon={faFileLines} />
                            <p className="text- w-11/12 truncate hover:text-clip ">
                              {index + 1}. {attachment.name}
                            </p>
                          </div>
                          <div className="above-icon flex w-[20px] justify-end">
                            <FontAwesomeIcon
                              icon={
                                attachment.is_public === 1 ||
                                LectureInfoSuccess?.data?.session?.is_buy === 1
                                  ? faLockOpen
                                  : faUnlockKeyhole
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StudentLecture;
