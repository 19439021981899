import SocialLinks from "../../common/social-links";
import React from "react";
import { FooterCopyRight } from "./common-footer";
import Logo from "../../../../assets/logo/black-logo.png";
import PhoneIcon from "../../../../assets/footer/call-icon.png";
import { Row, Col, Layout, Typography, Space } from "antd";

const { Footer: AntFooter } = Layout;
const { Title, Paragraph } = Typography;

const footer_content = {
  footer_logo: Logo,
  about: "About Us",
  about_des: (
    <>
      Dr. Tamer encourages his teaching staff members to adopt innovative
      techniques and methodologies in their classrooms. He recognizes that
      Continuous Professional Development
    </>
  ),
  phone_icon: PhoneIcon,
  address: <>1300 Don City, NYC</>,
  phone: <>000-222-9090</>,
};

const { footer_logo, about, about_des, phone_icon, address, phone } =
  footer_content;

const Footer = ({ tp_border }) => {
  return (
    <>
      <AntFooter className={`bs-footer ${tp_border && "tp-border-top"}`}>
        <div className="container">
          <div className="bs-footer__top fotter-btn-bottom pt-100 pb-20 d-none d-md-block">
            <Row align="middle">
              <Col xs={24} md={12}>
                <div className="bs-footer__top-logo">
                  <img src={footer_logo} alt="brand-logo" width={150} />
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div
                  className="bs-footer__top-social"
                  style={{ textAlign: "right" }}
                >
                  <SocialLinks />
                </div>
              </Col>
            </Row>
          </div>
          <div className="bs-footer__main pb-10 pt-20 tp-border-bottom">
            <Row justify="space-between">
              <Col xs={24} md={12}>
                <div className="tp-footer__widget mb-40">
                  <Title level={3} className="tp-footer__widget-title mb-35">
                    {about}
                  </Title>
                  <Paragraph className="pr-40">{about_des}</Paragraph>
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="tp-footer-cta d-flex align-items-center justify-end">
                  <Space align="center" size="large">
                    <span className="call-icon">
                      <img src={phone_icon} alt="" />
                    </span>
                    <span>
                      <Paragraph className="mb-0">{address}</Paragraph>
                      <b>
                        <a href={`callto: ${phone}`}>Call Us: {phone}</a>
                      </b>
                    </span>
                  </Space>
                </div>
              </Col>
            </Row>
          </div>
          {/* footer copy right start */}
          <FooterCopyRight />
          {/* footer copy right end */}
        </div>
      </AntFooter>
    </>
  );
};

export default Footer;
