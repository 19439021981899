import {
  faFacebook,
  faTwitter,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// social_links
const social_links = [
  {
    link: "https://www.facebook.com/EnglishwithTamer?mibextid=LQQJ4d",
    target: "_blank",
    icon: faFacebook,
    name: "Facebook",
  },
  {
    link: "https://api.whatsapp.com/send?phone=966564387568",
    target: "_blank",
    icon: faWhatsapp,
    name: "Whatsapp",
  },
];

const SocialLinks = () => {
  return (
    <>
      {social_links.map((item, i) => (
        <a key={i} target="_blank" href={item.link} rel="noreferrer">
          <FontAwesomeIcon icon={item.icon} />{" "}
        </a>
      ))}
    </>
  );
};
export default SocialLinks;
