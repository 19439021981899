import HelmetTags from "./HelmetTags";

function NotFound() {
  <HelmetTags title="This page does not exist. Please return to the home page." />;
  return (
    <div className="w-full h-screen flex justify-center items-center"></div>
  );
}

export default NotFound;
