import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuthState } from "../../../MainComponents/GlobalContext";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../MainComponents/HelmetTags";
import VideoCard from "../../../MainComponents/VideoCard";
import { Loader } from "../../../MainComponents";

const StudentMaterials = ({
  section,
  reading,
  videos,
  pdfs,
  pdfsType,
  practicesType,
  landing,
}) => {
  const AuthState = useAuthState();
  const { courseID, sessionID } = useParams();
  const pathReading = reading ? "reading" : "writing";

  const [showVideo, setShowVideo] = useState({
    show: false,
    src: "",
  });

  const api = videos
    ? `${process.env.REACT_APP_STUDENT_ALL_MATRIALES_API}/${section}/${pathReading}/videos`
    : pdfs && pdfsType !== "past-paper"
      ? `${process.env.REACT_APP_STUDENT_ALL_MATRIALES_API}/${section}/${pathReading}/pdfs`
      : pdfs && pdfsType === "past-paper"
        ? `${process.env.REACT_APP_STUDENT_ALL_MATRIALES_PAST_PAPER_API}/${section}`
        : `${process.env.REACT_APP_STUDENT_ALL_MATRIALES_PRACTICES_API}/${section}/${pathReading}`;

  const landingApi = videos
    ? `${process.env.REACT_APP_VISITER_ALL_MATRIALES_API}/${section}/${pathReading}/videos`
    : pdfs && pdfsType !== "past-paper"
      ? `${process.env.REACT_APP_VISITER_ALL_MATRIALES_API}/${section}/${pathReading}/pdfs`
      : pdfs && pdfsType === "past-paper"
        ? `${process.env.REACT_APP_VISITER_ALL_MATRIALES_PAST_PAPER_API}/${section}`
        : `${process.env.REACT_APP_VISITER_ALL_MATRIALES_PRACTICES_API}/${section}/${pathReading}`;

  //!---- get all Matrials -------
  const [GetMatrialsSuccess, GetMatrialsErrors, GetMatrialsLoading] = useAxios(
    landing ? landingApi : api,
    "GET",
    section,
    section
  );

  const filteredData = GetMatrialsSuccess?.data?.filter((card) => {
    if (pdfsType) {
      return card.pdftype === pdfsType;
    }

    if (practicesType === "exams") {
      return card.type === "exams";
    }

    if (practicesType === "topics") {
      return card.quiz_type === "topics";
    }

    if (practicesType === "drills") {
      return card.quiz_type === "drills";
    }

    return card;
  });

  return (
    <section className="h-auto w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags
        title={`${section.toUpperCase()} Matrials | Dr.Tamer Ghonim`}
      ></HelmetTags>

      {showVideo.show && (
        <div
          onClick={() => setShowVideo((prev) => ({ ...prev, show: false }))}
          className={`fixed inset-0 z-50 flex h-screen w-full origin-bottom items-center justify-center bg-blue-200/40 transition-all duration-300 ease-in-out ${
            showVideo.show === true
              ? "opacity- pointer-events-auto scale-100"
              : "opacity- pointer-events-none scale-0 hidden"
          } `}
        >
          <div className="iframe-wrapper aspect-video w-3/4 overflow-hidden rounded-3xl border-0 border-secondary">
            <div
              className="flex items-center justify-center h-full"
              dangerouslySetInnerHTML={{
                __html: showVideo.src,
              }}
            />
          </div>
        </div>
      )}

      <div className="mb-20 flex flex-col items-start gap-20">
        <div className="top-btns flex w-full justify-start gap-10 asm:flex-col asm:items-center asm:justify-start asm:gap-14">
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("")
            )) && (
            <Link
              to={`/admin/courses/${courseID}/session/${sessionID}/add-link`}
              className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Add Link
            </Link>
          )}
        </div>
        {GetMatrialsLoading && (
          <div className="w-full">
            <Loader />
          </div>
        )}
        {GetMatrialsErrors && (
          <p className="text-bolder text-center w-full">{GetMatrialsErrors}</p>
        )}
        <div className="videos video-grid-auto-fit w-full ">
          {filteredData?.length === 0 ? (
            <p>Threre is no matrials</p>
          ) : (
            filteredData?.map((video) => (
              <VideoCard
                setShowVideo={setShowVideo}
                video={video}
                key={video.key}
                type={section}
                landing={landing}
              />
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export default StudentMaterials;
