import useAxios from "../../../MainComponents/Hooks/useAxios";
import Loader from "../../../MainComponents/Loader";
import HelmetTags from "../../../MainComponents/HelmetTags";
import { useParams } from "react-router-dom";
import CardS2 from "../../../MainComponents/CardS2.jsx";

function StudentSessions({ myCourses }) {
  const { courseID } = useParams();

  //!---- get all sessions  -------
  const api = process.env.REACT_APP_STUDENT_COURSES_SESSIONS_API;

  const [allSessionsData, allSessionsErrors, allSessionsLoading] = useAxios(
    `${api}/${courseID}`,
    "GET",
    "GET"
  );

  if (allSessionsLoading) {
    return <Loader />;
  }

  if (allSessionsErrors) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <p>{allSessionsErrors}</p>
      </div>
    );
  }

  return (
    <section className=" width  mb-32 h-full  dark:bg-dark dark:text-light">
      <HelmetTags title="Sessions | Dr.Tamer Ghonim"></HelmetTags>
      <div className="lectures grid-auto-fit  mt-36">
        {allSessionsData?.data?.length === 0 ? (
          <p>No Sessions </p>
        ) : (
          allSessionsData?.data?.map((Card) => (
            <CardS2 data={Card} myCourses={myCourses} />
          ))
        )}
      </div>
    </section>
  );
}

export default StudentSessions;
