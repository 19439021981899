import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useForm } from "react-hook-form";
import { useEffect, useReducer, useState } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useNavigate, useParams } from "react-router-dom";

const AdminAddEditMatrialSession = ({ edit, type }) => {
  const [values, setValues] = useState(null);
  const { courseID, sessionID } = useParams();
  const navigate = useNavigate();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditMatrial": {
        return {
          ...state,
          submitAddEditMatrial: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditMatrial: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_ADMIN_COURSE_SESSION_EDIT_MATERIAL_API
    : process.env.REACT_APP_ADMIN_COURSE_SESSION_ADD_MATERIAL_API;

  //!--------- add edit Video -------

  const [MatrialAddEditSuccess, MatrialAddEditErrors, submitLoading] = useAxios(
    api,
    "POST",
    state.submitAddEditMatrial.flag,
    state.submitAddEditMatrial.dependency,
    state.submitAddEditMatrial.data,
    true
  );

  //!--------- get the Video info for editing -------

  const [MatrialInfo, MatrialInfoErrors] = useAxios(
    `${process.env.REACT_APP_ADMIN_COURSE_SESSION_GET_MATERIAL_INFO_API}/${sessionID}`,
    "GET",
    edit,
    edit
  );
  useEffect(() => {
    if (MatrialInfo) {
      setValues({
        ...MatrialInfo.data,
        is_free: MatrialInfo.data.is_free === 1 ? true : false,
      });
    }
  }, [MatrialInfo]);

  useEffect(() => {
    //!---add actions ----
    if (MatrialAddEditSuccess) {
      navigate(-1);
      reset();
    }
  }, [MatrialAddEditSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched", values });

  const onSubmit = (data) => {
    const finalData = edit
      ? {
          ...data,
          type,
          id: sessionID,
          is_free: +data?.is_free,
          link: type === "pdf" ? data.link[0] : data.link,
        }
      : {
          ...data,
          type,
          session_id: sessionID,
          is_free: +data?.is_free,
          link: type === "pdf" ? data.link[0] : data.link,
        };
    dispatch({
      type: "setSubmitAddEditMatrial",
      payload: {
        flag: "AddEditMatrial",
        dependency: !state.submitAddEditMatrial.dependency,
        data: finalData,
      },
    });
  };

  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Videos | Dr.Tamer Ghonim"></HelmetTags>
      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          {edit ? "Edit " : "Please fill in the information to add the "}
          {type === "video"
            ? " Video"
            : type === "pdf"
              ? "PDF"
              : type === "link"
                ? "Link"
                : null}
        </h2>

        <form
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3xl bg-white p-16 shadow-lg shadow-blue-200/50"
        >
          <div className="flex w-full flex-col items-start justify-center  gap-5  md:w-full">
            {/* name  */}
            <div className=" flex w-full flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="name">Name</label>
              <input
                className="signin-inputs w-full pl-4    "
                type="text"
                id="name"
                placeholder="Name"
                name="name"
                autoComplete="on"
                {...register("name", {
                  required: true,
                  //pattern: /^[A-Za-z]+$/,
                  maxLength: 40,
                  minLength: 3,
                })}
              />
              {errors.name && (
                <p className="w-full  text-[12px] text-blue-900  ">
                  {errors.name.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}

              {
                //!-------server errors -----

                MatrialAddEditErrors &&
                  MatrialAddEditErrors?.response?.data?.errors?.name && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {MatrialAddEditErrors?.response?.data?.errors?.name[0]}
                    </p>
                  )
              }
            </div>

            {/** video */}
            {type === "video" && (
              <div className="flex w-full flex-col items-end justify-center gap-2 md:w-full">
                <label className="w-full truncate" htmlFor="link">
                  Embed Code
                </label>
                <input
                  className="signin-inputs w-full pl-4    "
                  type="text"
                  id="link"
                  placeholder="Embed Code"
                  name="link"
                  autoComplete="on"
                  {...register("link", {
                    required: true,
                    //pattern: /^[A-Za-z]+$/,
                    minLength: 3,
                  })}
                />
                {errors.link && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {errors.link.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  MatrialAddEditErrors &&
                    MatrialAddEditErrors?.response?.data?.errors?.link && (
                      <p className="w-full  text-[12px] text-blue-900  ">
                        {MatrialAddEditErrors?.response?.data?.errors?.link[0]}
                      </p>
                    )
                }
              </div>
            )}

            {/* pdf  */}
            {type === "pdf" && (
              <div className="name flex w-full flex-col items-start justify-center  gap-2  md:w-full">
                <label className="w-1/2 text-start " htmlFor="link">
                  Add File
                </label>

                <input
                  id="link"
                  className="signin-inputs   w-full"
                  type="file"
                  accept=".pdf,.doc, .docx"
                  name="link"
                  {...register("link", {
                    required: values ? false : true,
                    validate: (value) => !(value[0]?.size > 1024 * 1024 * 1024),
                  })}
                />

                {errors.link && (
                  <p className="mt-2 w-full text-end text-[12px] text-blue-900">
                    {errors.link.type === "required" && "Please Add File"}
                    {errors.link.type === "validate" &&
                      "Maximum file size is 1 GB"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  MatrialAddEditErrors &&
                    MatrialAddEditErrors?.response?.data?.errors?.link && (
                      <p className="w-full text-end text-[12px] text-blue-900  ">
                        {MatrialAddEditErrors?.response?.data?.errors?.link[0]}
                      </p>
                    )
                }
              </div>
            )}

            {/* link  */}
            {type === "link" && (
              <div className=" link flex w-full flex-col items-end justify-center gap-2 md:w-full">
                <label className="w-full truncate" htmlFor="link">
                  video link
                </label>
                <input
                  className="signin-inputs w-full pl-4    "
                  type="text"
                  id="link"
                  placeholder="video link"
                  name="link"
                  autoComplete="on"
                  {...register("link", {
                    required: true,
                    //pattern: /^[A-Za-z]+$/,
                    maxLength: 155,
                    minLength: 3,
                  })}
                />
                {errors.link && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {errors.link.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  MatrialAddEditErrors &&
                    MatrialAddEditErrors?.response?.data?.errors?.link && (
                      <p className="w-full  text-[12px] text-blue-900  ">
                        {MatrialAddEditErrors?.response?.data?.errors?.link[0]}
                      </p>
                    )
                }
              </div>
            )}

            <div className=" visibility flex w-full flex-col items-end justify-center gap-2 md:w-full">
              <div className="flex w-full items-center justify-end gap-10">
                <label className="visibility-switch">
                  <input
                    className=""
                    id="is_free"
                    name="is_free"
                    {...register("is_free", {
                      required: false,
                    })}
                    type="checkbox"
                  />
                  <span className="visibility-slider">
                    <svg
                      className="slider-icon"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path fill="none" d="m4 16.5 8 8 16-16"></path>
                    </svg>
                  </span>
                </label>
                <div>
                  <label
                    className="w-full cursor-pointer truncate"
                    htmlFor="is_free"
                  >
                    Free
                  </label>
                </div>
              </div>

              {errors.is_free && (
                <p className="text-[12px] text-white ">
                  {errors.is_free.type === "required" && "برجاء ملئ هذا الحقل"}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                MatrialAddEditErrors &&
                  MatrialAddEditErrors?.response?.data?.errors?.is_free && (
                    <p className="w-full text-end text-[12px] text-white  ">
                      {MatrialAddEditErrors?.response?.data?.errors?.is_free[0]}
                    </p>
                  )
              }
            </div>
          </div>

          {/** submit */}

          <button
            disabled={
              !isValid || (submitLoading && state.submitAddEditMatrial.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && state.submitAddEditMatrial.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>
                {edit ? "Edit " : "Add "}
                {type === "video"
                  ? " Video"
                  : type === "pdf"
                    ? "PDF"
                    : type === "link"
                      ? "Link"
                      : null}
              </p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
};

export default AdminAddEditMatrialSession;
