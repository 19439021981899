import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faFileAlt,
  faFilePdf,
  faPenAlt,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import HelmetTags from "../../../MainComponents/HelmetTags";

function StudentMaterialsViewReadingOrWriting({ section }) {
  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 sm:px-10 ">
      <HelmetTags title={`Matrials | Dr.Tamer Ghonim `}></HelmetTags>

      <div className="mb-20 flex flex-col items-start gap-20 ">
        <h2 className="w-full text-4xl font-bold text-center">
          {section.toUpperCase()} Materials
        </h2>

        <div className="top-btns flex w-full justify-start gap-5 asm:flex-col asm:items-center ">
          <div className="w-1/3 md:w-full">
            <Link
              to={"videos"}
              class="service-card  md:w-full shadow-xl cursor-pointer snap-start shrink-0 py-8 px-6 bg-white flex flex-col items-start gap-3 transition-all duration-300 group hover:bg-secondary hover:text-white"
            >
              <FontAwesomeIcon icon={faVideo} />

              <p class="font-bold text-3xl group-hover:text-white text-secondary">
                Videos
              </p>
            </Link>
          </div>
          <div className="w-1/3 md:w-full">
            <Link
              to={"pdfs"}
              class="service-card shadow-xl cursor-pointer snap-start shrink-0 py-8 px-6 bg-white flex flex-col items-start gap-3 transition-all duration-300 group hover:bg-secondary  hover:text-white"
            >
              <FontAwesomeIcon icon={faFilePdf} />

              <p class="font-bold text-3xl group-hover:text-white text-secondary">
                Pdfs
              </p>
            </Link>
          </div>
          <div className="w-1/3 md:w-full">
            <Link
              to={"practices"}
              class="service-card shadow-xl cursor-pointer snap-start shrink-0 py-8 px-6 bg-white flex flex-col items-start gap-3 transition-all duration-300 group hover:bg-secondary hover:text-white"
            >
              <FontAwesomeIcon icon={faFileAlt} />

              <p class="font-bold text-3xl group-hover:text-white text-secondary">
                Online Practices
              </p>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StudentMaterialsViewReadingOrWriting;
