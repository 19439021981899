import { useState } from "react";
import { useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import QuestionBankCategory from "../QuestionBank/QuestionBankCategory.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import Loader from "../../../../MainComponents/Loader.jsx";
import AddEditChooseQuestion from "../QuestionBank/AddEditChooseQuestion.jsx";
import AdminAddEditParagraphe from "./AdminAddEditParagraphe.jsx";

function AdminQuizSection({ homework, placement, type }) {
  const { examID, courseID, sectionNumber } = useParams();

  const [refetch, setRefetch] = useState(false);

  //!--------- get quiz / homework info for checking if there is a quiz or a homework has set previously  -------
  let InfoApi = placement
    ? `${process.env.REACT_APP_ADMIN_GET_SECTION_QUESTION_PLACEMENT_TEST_API}/${courseID}/${sectionNumber}`
    : `${process.env.REACT_APP_ADMIN_TRIAL_EXAM_GET_ALL_SECTION_INFO_API}${examID}/${sectionNumber}`;

  const [getInfoSuccess, getInfoErrors, getInfoLoading] = useAxios(
    InfoApi,
    "GET",
    "GET",
    refetch
  );

  if (getInfoLoading) {
    return <Loader />;
  }

  return (
    <section className="flex h-auto w-full flex-col items-start gap-20 overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title={`Tests | Dr.Tamer Ghonim`}></HelmetTags>

      <AdminAddEditParagraphe
        getInfoSuccess={getInfoSuccess}
        refetch={refetch}
        setRefetch={setRefetch}
        placement={placement}
      />

      <div className="custom-question  w-full ">
        <h2 className=" text-3xl font-bold mb-6">Add a Question </h2>

        <AddEditChooseQuestion
          type={placement ? "placementTest" : "homework"}
          id={getInfoSuccess?.data?.quiz_info[0]?.key}
          refetch={refetch}
          setRefetch={setRefetch}
          placement={placement}
          questionType={type}
        />
      </div>
      {getInfoSuccess?.data?.Questions?.length > 0 && (
        <div className="all-custom-questions w-full ">
          <h2 className=" text-3xl font-bold mb-8"> All Questions </h2>
          <div className="flex gap-4">
            {getInfoSuccess?.data?.paragraph && (
              <div className=" flex-1  bg-white rounded-2xl h-[90vh] overflow-y-auto ">
                <div
                  className=" px-4"
                  dangerouslySetInnerHTML={{
                    __html: getInfoSuccess?.data?.paragraph,
                  }}
                />
              </div>
            )}

            <div className=" flex-1  bg-white rounded-2xl h-[90vh] overflow-y-auto">
              <QuestionBankCategory
                type={homework ? "homework" : "quiz"}
                id={
                  homework
                    ? getInfoSuccess?.data?.homework_info[0].key
                    : getInfoSuccess?.data?.quiz_info[0]?.key
                }
                QuizQuestionsData={getInfoSuccess?.data?.Questions}
                setRefetchQuiz={setRefetch}
                refetchQuiz={refetch}
                questionType={type}
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default AdminQuizSection;
