import { useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AddEditSkill({ addEditSkill }) {
  const { skillID, section } = useParams();
  const navigate = useNavigate();

  function reducer(state, action) {
    switch (action.type) {
      case "setErrors": {
        return {
          ...state,
          errors: action.payload,
        };
      }

      case "setSubmitAddEditSkill": {
        return {
          ...state,
          submitAddEditSkill: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditSkill: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let apiAddEditDomain = process.env.REACT_APP_ADD_DOMAIN_API;

  let apiAddEditSkill = process.env.REACT_APP_ADD_SKILL_API;
  //!--------- add edit Skill -------

  const [skillAddEditSuccess, skillAddEditErrors, skillAddEditLoading] =
    useAxios(
      addEditSkill ? apiAddEditSkill : apiAddEditDomain,
      "POST",
      state.submitAddEditSkill.flag,
      state.submitAddEditSkill.dependency,
      state.submitAddEditSkill.data,
      true
    );

  //!--------- get the Skill info for editing -------

  useEffect(() => {
    //!---add actions ----

    if (skillAddEditSuccess) {
      navigate(-1);
      reset();
    }
    //! make the dependency false again to listen to the next try to submit
  }, [skillAddEditSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data) => {
    const finalData = skillID
      ? {
          ...data,
          id: skillID,
          section: section,
        }
      : {
          ...data,
          section: section,
        };
    dispatch({
      type: "setSubmitAddEditSkill",
      payload: {
        flag: "AddEditSkill",
        dependency: !state.submitAddEditSkill.dependency,
        data: finalData,
      },
    });
  };

  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Skills | Dr.Tamer Ghonim"></HelmetTags>

      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          Please fill in the information to
          {skillID ? " Edit " : " Add "} the Skill
        </h2>

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3xl bg-white p-16 shadow-lg shadow-blue-200/50"
        >
          {/** Name stage filed */}
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            <div className="name flex w-full flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="name">Name</label>
              <input
                className="signin-inputs w-full pl-4"
                type="text"
                id="name"
                placeholder="Name"
                name="name"
                autoComplete="on"
                {...register("name", {
                  required: true,
                  maxLength: 40,
                  minLength: 3,
                })}
              />
              {errors.name && (
                <p className="w-full  text-[12px] text-blue-900  ">
                  {errors.name.type === "required" &&
                    "Please fill out this field"}
                  {errors.name.type === "maxLength" &&
                    "The maximum number of characters is 40 characters"}
                  {errors.name.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}
              {
                //!-------server errors -----

                skillAddEditErrors &&
                  skillAddEditErrors?.response?.data?.errors?.name && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {skillAddEditErrors?.response?.data?.errors?.name[0]}
                    </p>
                  )
              }
            </div>
          </div>

          {/** submit */}
          <button
            disabled={
              !isValid || (skillAddEditLoading && state.submitAddEditSkill.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {skillAddEditLoading && state.submitAddEditSkill.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p> {addEditSkill ? "Add Skill" : "Add Domain"}</p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default AddEditSkill;
