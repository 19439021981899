import { useEffect, useMemo, useReducer } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import QuizHeader from "./component/quizHeader/QuizHeader";
import { DisplayQuestion } from "./component/displayQuestion/DisplayQuestion";
import Question from "./component/displayQuestion/Question";
import QuizFooter from "./component/quizFooter/QuizFooter";
import RightQuestionsSidebar from "./component/quizHeader/RightQuestionsSidebar";
import DisplayAnswers from "./component/displayAnswers/DisplayAnswers";
import QuestionsAnswers from "./component/displayAnswers/QuestionsAnswers";
import PercentageSidebar from "./component/displayAnswers/PercentageSidebar";
import HelmetTags from "../../../MainComponents/HelmetTags";
import Loader from "../../../MainComponents/Loader";

function reducer(state, action) {
  switch (action.type) {
    case "setLoading": {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case "setCollapse": {
      return {
        ...state,
        collapse: !state.collapse,
      };
    }

    case "setQuestions": {
      return {
        ...state,
        questions: action.payload,
      };
    }

    case "setQuizInfo": {
      return {
        ...state,
        quizInfo: action.payload,
      };
    }

    case "setCurrentSectionIndex": {
      return {
        ...state,
        collapse: false,
        currentSectionIndex: action.payload,
      };
    }

    case "setIsShowAnswers": {
      return {
        ...state,
        isShowAnswers: true,
      };
    }

    case "setIsSelectedAnswer": {
      return {
        ...state,
        questions: state.questions.map((question) =>
          question.id === action.payload.questionId
            ? {
                ...question,
                selected_answer: action.payload.answer,
                is_selected: action.payload.questionId,
                n: action.payload.n,
              }
            : question
        ),
      };
    }

    case "setAnsweredQuestions": {
      // Check if answer already exists
      const answerExists = state.answeredQuestions.some(
        (answer) => answer.id === action.payload.id
      );

      if (!answerExists) {
        return {
          ...state,
          answeredQuestions: [...state.answeredQuestions, action.payload],
        };
      } else {
        return {
          ...state,
          answeredQuestions: state.answeredQuestions.map((question) =>
            question.id === action.payload.id
              ? {
                  ...question,
                  order: action.payload.order,
                  id: action.payload.id,
                  n: action.payload.n,
                }
              : question
          ),
        };
      }
    }

    case "setFinishQuizData": {
      return {
        ...state,
        finishQuizData: action.payload,
      };
    }

    case "setAllData": {
      return {
        ...initialState,
      };
    }
    case "setRefetch": {
      return {
        ...state,
        refetch: action.payload,
      };
    }

    default:
      throw Error("Unknown action: " + action.type);
  }
}

const initialState = {
  loading: true,
  currentSectionIndex: 1,
  collapse: false,
  questions: [],
  quizInfo: {},
  answeredQuestions: [],
  finishQuizData: {},
  numOfSelected: 0,
  isShowAnswers: false,
  refetch: false,
};

const QuizNew = ({ homework, exam, placement }) => {
  const { ID } = useParams();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(reducer, initialState);

  const checkFinishApi = homework
    ? process.env.REACT_APP_STUDENT_HOMEWORK_SUBMIT_API
    : exam
      ? process.env.REACT_APP_STUDENT_TRIAL_EXAM_SUBMIT_API
      : placement
        ? process.env.REACT_APP_STUDENT_SUBMIT_PLACEMENT_TEST_API
        : process.env.REACT_APP_STUDENT_QUIZZ_SUBMIT_API;

  const apiData = placement
    ? `${process.env.REACT_APP_STUDENT_GET_PLACEMENT_TEST_API}/${ID}`
    : `${process.env.REACT_APP_STUDENT_START_MATRIALE_EXAM_PRACTISE_API}/${ID}`;

  const [quizData, quizErrors, quizLoading] = useAxios(
    apiData,
    "GET",
    "GET",
    state.refetch,
    ""
  );

  useEffect(() => {
    dispatch({
      type: "setQuestions",
      payload: quizData
        ? [...quizData?.choosequestions, ...quizData?.essayquestions]
        : [],
    });

    dispatch({
      type: "setQuizInfo",
      payload: quizData ? quizData.quiz : {},
    });
    dispatch({ type: "setRefetch", payload: false });
  }, [quizData]);

  useEffect(() => {
    state.questions
      // .filter((question) => question.is_selected === 1)
      .map((question) => {
        dispatch({
          type: "setAnsweredQuestions",
          payload: {
            id: question.id,
            order: question.selected_answer || "E",
            n: question.n,
          },
        });
        return null;
      });
  }, [state.questions]);

  useMemo(() => {
    // set axios
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(localStorage.userData).student_token;
  }, []);

  const onFinishQuiz = () => {
    async function fetchData() {
      try {
        let res = await axios.post(checkFinishApi, {
          lecture_id: ID,
          answers: state.answeredQuestions,
        });
        dispatch({ type: "setFinishQuizData", payload: res.data.data });
        // navigate(-1);
        dispatch({ type: "setIsShowAnswers" });
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
    fetchData();
  };

  return (
    <section
      className={`flex h-fit  min-h-screen w-full flex-col items-center`}
    >
      <HelmetTags title={`Test | Dr.Tamer Ghonim `}></HelmetTags>
      <QuizHeader
        state={state}
        dispatch={dispatch}
        onFinishQuiz={onFinishQuiz}
        quizErrors={quizErrors}
        quizLoading={quizLoading}
        exam={exam}
        quizData={quizData}
      />

      {quizErrors && (
        <div className="mt-10 ">
          <p>{quizErrors}</p>
        </div>
      )}

      {quizLoading && <Loader />}

      {!quizLoading && !quizErrors && (
        <main
          className={`w-full min-h-full min-h-fit md:min-h-fit md:flex-col md:gap-[40px] flex max-w-[1700px] justify-center`}
        >
          {!state.isShowAnswers ? (
            <DisplayQuestion state={state}>
              {quizData?.paragraph.length > 0 ? (
                <div className="flex gap-5 w-full">
                  <p
                    className="flex-1 bg-white rounded-2xl h-[90vh] overflow-y-auto"
                    dangerouslySetInnerHTML={{
                      __html:
                        quizData?.paragraph[state.currentSectionIndex - 1]
                          ?.paragraph,
                    }}
                  />
                  <Question
                    state={state}
                    dispatch={dispatch}
                    homework={homework}
                    exam={exam}
                    isParagraphe
                    placement={placement}
                  />
                </div>
              ) : (
                <>
                  <Question
                    state={state}
                    dispatch={dispatch}
                    homework={homework}
                    exam={exam}
                    placement={placement}
                  />
                </>
              )}
              <QuizFooter
                state={state}
                dispatch={dispatch}
                onFinishQuiz={onFinishQuiz}
                isParagraphe={quizData?.paragraph.length > 0}
                quizData={quizData}
              />
            </DisplayQuestion>
          ) : (
            <DisplayAnswers>
              <QuestionsAnswers state={state} dispatch={dispatch} exam={exam} />
              <PercentageSidebar state={state} />
            </DisplayAnswers>
          )}

          <RightQuestionsSidebar
            state={state}
            dispatch={dispatch}
            quizData={quizData}
          />
        </main>
      )}
    </section>
  );
};

export default QuizNew;
