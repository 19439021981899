import SingleCourse from "../../../MainComponents/SingleCourse";
import { useAuthState } from "../../../MainComponents/GlobalContext.jsx";
import SingleChapter from "../../../MainComponents/SingleChapter";

import useAxios from "../../../MainComponents/Hooks/useAxios";
import Loader from "../../../MainComponents/Loader";
import HelmetTags from "../../../MainComponents/HelmetTags";

function StudentLectures({ landing, myCourses }) {
  const AuthState = useAuthState();

  //!---- get all lectures  -------

  const api = myCourses
    ? process.env.REACT_APP_STUDENT_MY_COURSES_API
    : process.env.REACT_APP_STUDENT_COURSES_API;

  const [allLecturesData, allLecturesErrors, allLecturesLoading] = useAxios(
    api,
    "GET",
    "GET",
    myCourses
  );

  if (allLecturesLoading) {
    return <Loader />;
  }
  if (allLecturesErrors) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <p>{allLecturesErrors}</p>
      </div>
    );
  }
  return (
    <section className=" width  mb-32 h-full  dark:bg-dark dark:text-light">
      <HelmetTags title="Courses | Dr.Tamer Ghonim"></HelmetTags>
      <div className="lectures grid-auto-fit  mt-36">
        {allLecturesData?.data?.length === 0 ? (
          <p>No lectures</p>
        ) : (
          allLecturesData?.data?.map((Card) => (
            <SingleCourse
              lecture={Card}
              key={Card.key}
              notSigned={landing ? true : false}
              myCourses={myCourses}
            />
          ))
        )}
      </div>
    </section>
  );
}

export default StudentLectures;
