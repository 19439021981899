import { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import Loader from "../../../../MainComponents/Loader.jsx";
import { Popconfirm } from "antd";

function QuestionBankCategory({
  type,
  QuizQuestionsData,
  refetchQuiz,
  setRefetchQuiz,
  ID,
}) {
  const AuthState = useAuthState();
  const [deleteId, setDeleteId] = useState("");
  const allQuestions = useRef(null);
  const [reFetch, setReFetch] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const navigate = useNavigate();
  const { sectionNumber } = useParams();
  console.log(sectionNumber);
  //!--------- get all Questions -------
  const [allQuestionsData, allQuestionsErrors, loading] = useAxios(
    `${process.env.REACT_APP_CATEGORY_QUESTIONS_API}${sectionNumber}`,
    "GET",
    !type,
    reFetch
  );
  //!--------- delete Question -------

  const [deleteQuestionSuccess] = useAxios(
    process.env.REACT_APP_DELETE_QUESTION_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );

  useEffect(() => {
    if ((QuizQuestionsData?.length > 0 || allQuestionsData) && allQuestions) {
      QuizQuestionsData?.length > 0
        ? setFinalData(QuizQuestionsData)
        : setFinalData(allQuestionsData?.data);
    }
  }, [allQuestionsData, QuizQuestionsData, allQuestions]);

  useEffect(() => {
    //! refetch the data on success delete Question

    if (deleteQuestionSuccess) {
      type ? setRefetchQuiz(!refetchQuiz) : setReFetch(!reFetch);
      setDeleteId("");
    }
  }, [deleteQuestionSuccess]);

  function handleDelete(id) {
    setSubmitDelete({
      flag: "deleteQuestion",
      dependency: !submitDelete.dependency,
      data: { id },
    });
  }

  if (loading && !type) {
    return <Loader />;
  }

  return (
    <section
      className={`h-full w-full overflow-y-auto py-10 dark:bg-dark dark:text-light sm:px-10`}
    >
      {!type && (
        <h2 className="mb-16 w-full  text-4xl font-bold sm:text-center">
          Questions
        </h2>
      )}

      <div className="all-questions flex h-auto w-full flex-col gap-6 px-10 amd:px-0">
        {finalData?.map((question, index) => (
          <div
            key={index}
            className="singleQuestionWrapper rounded-2xl bg-white"
          >
            <div className="optionsQuestionWrapper">
              <div className="categoryQuestionWrapper">
                {question.question ? (
                  <>
                    <span>{index + 1}</span>
                    <p
                      className="essayTitleWrapper"
                      dangerouslySetInnerHTML={{
                        __html: question.question,
                      }}
                    />
                  </>
                ) : (
                  <a href={question.url} target="_blank" rel="noreferrer">
                    <span>{index + 1}</span>
                    {question.name}
                  </a>
                )}
              </div>
              <div className="questionOptions">
                <Link
                  to={`/admin/edit-choose-question/${question.key}`}
                  className="editQuestionBtn"
                >
                  Edit
                </Link>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#ffffff" //okButtonProps={{ loading: loading }}
                  onConfirm={() => handleDelete(question.key)}
                  title="Delete The Question?"
                >
                  <button className="deleteQuestionBtn">Delete</button>
                </Popconfirm>
              </div>
            </div>
            <div className="categoryAnswersWrapper">
              <div
                className={`categorySingleAnswer answer1Wrapper ${
                  question.correct_answer === "answer1" && "correctSingleAnswer"
                }`}
              >
                <div
                  className="flex items-center"
                  dangerouslySetInnerHTML={{
                    __html: question.answer1,
                  }}
                />
              </div>
              <div
                className={`categorySingleAnswer answer2Wrapper ${
                  question.correct_answer === "answer2" && "correctSingleAnswer"
                }`}
              >
                <div
                  className="flex items-center"
                  dangerouslySetInnerHTML={{
                    __html: question.answer2,
                  }}
                />
              </div>
              <div
                className={`categorySingleAnswer answer3Wrapper ${
                  question.correct_answer === "answer3" && "correctSingleAnswer"
                }`}
              >
                <div
                  className="flex items-center"
                  dangerouslySetInnerHTML={{
                    __html: question.answer3,
                  }}
                />
              </div>
              <div
                className={`categorySingleAnswer answer4Wrapper ${
                  question.correct_answer === "answer4" && "correctSingleAnswer"
                }`}
              >
                <div
                  className="flex items-center"
                  dangerouslySetInnerHTML={{
                    __html: question.answer4,
                  }}
                />
              </div>
            </div>
          </div>
        ))}
        {!type &&
          allQuestionsErrors &&
          typeof allQuestionsErrors === "string" && (
            <p className="w-full text-center">{allQuestionsErrors}</p>
          )}
      </div>
    </section>
  );
}

export default QuestionBankCategory;
