// copy right text
const footer_content = {
  copy_right_info: (
    <>
      {" "}
      Copyright & Design By <a href="https://natlix.com/">Natlix</a> -{" "}
      {new Date().getFullYear()}{" "}
    </>
  ),
};

const { copy_right_info } = footer_content;

export const FooterCopyRight = ({ style_3, style_7, style_9 }) => {
  return (
    <>
      <div
        className={`tp-footer__bottom pt-25 pb-25 text-center ${
          style_3 ? "da-ft-copyright-bg" : ""
        } ${style_7 ? "law-footer__bottom red-bg" : ""} ${
          style_9 ? "ha-footer-copyright" : ""
        }`}
      >
        <div className={`tp-copyrigh-text text-center`}>
          <span>{copy_right_info}</span>
        </div>
      </div>
    </>
  );
};
