import { useState } from "react";
import QuestionModalAnswer from "./QuestionModalAnswer";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faCircleCheck,
  faCircleXmark,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { Loader } from "../../../../MainComponents";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";

const ModalAnswer = ({ exam, placement }) => {
  const [collapse, setCollapse] = useState(false);
  const [activeQuestionId, setActiveQuestionId] = useState(0);
  const { ID } = useParams();
  const navigate = useNavigate();

  const [currentSection, setCurrentSection] = useState(1);

  const api = exam
    ? process.env.REACT_APP_STUDENT_TRIAL_EXAM_MODAL_ANSWER_API
    : placement
      ? process.env.REACT_APP_STUDENT_GET_MODAL_ANSWER_TEST_API
      : process.env.REACT_APP_STUDENT_QUIZZ_HOMEWORK_MODAL_ANSWER_API;

  const [modalAnswerData, modalAnswerErrors, modalAnswerLoading] = useAxios(
    `${api}/${ID}`,
    "GET",
    "GET",
    ""
  );

  function handleOnClickQuestion(id) {
    handelOpenCollapse();
    setActiveQuestionId(id);
  }

  function handelOpenCollapse() {
    setCollapse(true);
  }

  function handelCloseCollapse() {
    setCollapse(false);
  }

  return (
    <div className="left-response-container flex items-start md:w-full">
      <HelmetTags title="Model Answer | Dr.Tamer Ghonim "></HelmetTags>
      {/* <QuestionModalAnswer
        collapse={collapse}
        onCloseCollapse={handelCloseCollapse}
        activeQuestionId={activeQuestionId}
        question={modalAnswerData?.data[activeQuestionId]}
      /> */}
      <div className="all-questions flex h-full flex-col items-center overflow-y-auto  p-[40px] transition-all  duration-300 ease-in-out w-full md:order-2 md:w-full">
        {modalAnswerLoading && <Loader />}
        {modalAnswerErrors && (
          <div className="flex justify-center flex-col">
            <p>{modalAnswerErrors}</p>
            <button
              onClick={() => navigate(-1)}
              className="flex justify-center my-6"
            >
              <div className="collapse-btn top-[96px] flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-accent text-light shadow-md shadow-black/40 duration-300 hover:scale-110 hover:text-light">
                <FontAwesomeIcon
                  className={`transition-all duration-300 ease-in-out hover:scale-110`}
                  icon={faChevronRight}
                />
              </div>
            </button>
          </div>
        )}
        {!modalAnswerLoading && !modalAnswerErrors && (
          <>
            <h1 className="text-[20px] font-bold text-secondary dark:text-white ">
              Model Answer
            </h1>

            {modalAnswerData?.data?.map((section, index) => (
              <div
                key={index}
                className={`response-questions myactual-questions-container mt-[20px] w-full ${
                  currentSection === index + 1 ? "block" : "!hidden"
                }`}
              >
                <div className="flex gap-5 w-full">
                  {section?.paragraph?.paragraph && (
                    <p
                      className="flex-1 bg-white rounded-2xl h-[90vh] overflow-y-auto"
                      dangerouslySetInnerHTML={{
                        __html: section?.paragraph?.paragraph,
                      }}
                    />
                  )}

                  <div
                    className={`flex-1 h-[90vh] w-full overflow-y-auto py-10 sm:px-10 px-3 `}
                  >
                    {section?.questions?.map((question, i) => (
                      <div
                        key={i}
                        className="paragraphe modal-answer singleQuestionWrapper rounded-2xl bg-white mb-4"
                      >
                        <div className="optionsQuestionWrapper current-question flex-col !gap-0">
                          <div className="categoryQuestionWrapper">
                            {`${question.n}.`}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: question?.question,
                              }}
                            />
                          </div>
                          {/* <div className="current-question-degree w-full flex justify-end">
                            <p> {question?.degree} degree</p>
                          </div> */}
                        </div>

                        <div className="categoryAnswersWrapper current-question-answers">
                          {question?.answers?.map((answer, i) => (
                            <div
                              key={i}
                              className={`categorySingleAnswer answer1Wrapper
                            ${
                              question.correct_answer !==
                                question.student_answer &&
                              answer.order === question.student_answer &&
                              "none-selected"
                            }

                            ${
                              answer.order === question.correct_answer &&
                              "selected"
                            }`}
                            >
                              <span className="">{answer.order} </span>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: answer.answer,
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
            <div className="indicators flex w-full items-center justify-center gap-[20px] border-t-2 border-t-light py-[20px] sm:flex-col">
              <div className="correct flex items-center gap-[8px] justify-self-center text-[18px] text-lime-500">
                <FontAwesomeIcon icon={faCircleCheck} />
                <p>Correct Answer</p>
              </div>
              <div className="correct flex items-center gap-[8px] justify-self-center text-[18px] text-accent">
                <FontAwesomeIcon icon={faCircleXmark} /> <p>Wrong Answer</p>
              </div>
              {/* <div className="correct flex items-center gap-[8px] justify-self-center text-[18px] text-amber-400">
                <FontAwesomeIcon icon={faSpinner} spinPulse />{" "}
                <p>Waiting for correction</p>
              </div> */}
            </div>
          </>
        )}

        {/* <footer className="amd-hero:gap-[16px]  mt-[40px]  flex  w-full  items-center justify-between gap-[32px] rounded-lg bg-secondary p-[20px] ">
          <div className="numbering flex w-[160px] justify-center gap-[4px] rounded-md bg-light  p-[8px]  text-secondary md:w-[64px]">
            {modalAnswerData?.paragraph?.paragraph ? (
              <>
                <span className="current-question-number">
                  {state.questions.length} 
                  11
                </span>
                <span className="font-bold md:hidden">Questions</span>
              </>
            ) : (
              <>
                <span className="font-bold md:hidden">Section</span>
                <span className="current-question-number">
                  {currentSection}
                </span>
                <span className="font-bold">From</span>
                <span className="questions-count">
                  {modalAnswerData?.data?.length}
                </span>
              </>
            )}
          </div>

          <div className="control-buttons flex justify-center gap-[20px]">
            <button
              onClick={() => setCurrentSection((prev) => prev - 1)}
              disabled={currentSection === 1 ? true : false}
              className="prev-btn flex w-[96px] justify-center rounded-md border-2 border-light py-[4px] text-center font-bold  text-light duration-200 hover:scale-110 active:scale-90 md:w-[48px]"
            >
              <span className="md:hidden">Previous</span>
              <FontAwesomeIcon
                className="hidden rotate-180 text-[20px] font-bold md:block"
                icon={faChevronRight}
              />
            </button>

            <button
              onClick={() => setCurrentSection((prev) => prev + 1)}
              disabled={
                currentSection === modalAnswerData?.data?.length ? true : false
              }
              className="next-btn flex w-[96px] justify-center rounded-md border-2 border-light bg-light py-[4px]  text-center font-bold text-secondary duration-200 hover:scale-110 active:scale-90 md:w-[48px]"
            >
              <span className="md:hidden">Next</span>

              <FontAwesomeIcon
                className="hidden text-[20px] font-bold md:block"
                icon={faChevronRight}
              />
            </button>
          </div>
        </footer> */}
      </div>
    </div>
  );
};

export default ModalAnswer;
