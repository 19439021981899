import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { faGear, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../../MainComponents";

const StudentAllCourses = () => {
  const [allCoursesData, setAllCoursesData] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [formDependency, setFormDependency] = useState(false);
  const { studentID } = useParams("");

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  //!--------- add assistant -------

  const [
    GetStudentCoursesSuccess,
    GetStudentCoursesErrors,
    GetStudentCoursesLoading,
  ] = useAxios(
    `${process.env.REACT_APP_GET_STUDENT_COURSES_API}/${studentID}`,
    "GET",
    "GET",
    ""
  );

  const [
    EditStudentCoursesSuccess,
    EditStudentCoursesErrors,
    EditStudentCoursesLoading,
  ] = useAxios(
    process.env.REACT_APP_UPDATE_STUDENT_SUBSCRIBED_COURSES_API,
    "POST",
    formFlag,
    formDependency,
    formValues
  );

  const onSubmit = (formData) => {
    const FormArray = Object.entries(formData);
    const courses = [];
    FormArray.map((entry) => {
      if (entry[1] === true) {
        courses.push(entry[0]);
        return;
      } else if (entry[1] === false) {
        return;
      }
      return entry;
    });

    setFormValues({ id: studentID, studentcourses: courses });
    setFormFlag("EditAssistantPassword");
    setFormDependency(true);
  };

  useEffect(() => {
    if (EditStudentCoursesSuccess) {
      toast.success("Success..");
      setFormFlag("");
      setFormDependency(false);
    }
  }, [EditStudentCoursesSuccess]);

  useEffect(() => {
    if (EditStudentCoursesErrors) {
      toast.error(EditStudentCoursesErrors.message);
      setFormFlag("");
      setFormDependency(false);
    }
  }, [EditStudentCoursesErrors]);

  useEffect(() => {
    if (GetStudentCoursesSuccess) {
      setAllCoursesData([...GetStudentCoursesSuccess?.data]);
    }
  }, [GetStudentCoursesSuccess]);

  return (
    <form
      encType="multipart/form-data"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      className="mt-24 flex w-3/4 flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50 my-6"
    >
      <h2 className="mb-3 flex w-full items-center  gap-2  text-[17px] font-bold">
        <span>Change Students Courses</span>
      </h2>

      {GetStudentCoursesLoading ? (
        <Loader />
      ) : (
        <>
          <div className="student-permissions mt-0 flex h-fit w-full flex-col gap-7">
            <div className="input-label-container flex h-fit w-full flex-wrap-reverse items-center justify-evenly gap-10 rounded-3xl p-10 ">
              {allCoursesData?.map((course) => (
                <div
                  key={course.key}
                  className=" flex w-fit  items-center justify-center gap-16 md:w-full"
                >
                  <div className="checkbox-wrapper">
                    <div className="cbx">
                      <input
                        id={String(course.key)}
                        className="signin-inputs w-full"
                        type="checkbox"
                        name={String(course.key)}
                        defaultChecked={course.checked}
                        {...register(String(course.key))}
                      />
                      <label htmlFor={String(course.key)}></label>
                      <svg
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                      >
                        <path d="M2 8.36364L6.23077 12L13 2"></path>
                      </svg>
                    </div>

                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                      <defs>
                        <filter id="goo-12">
                          <feGaussianBlur
                            in="SourceGraphic"
                            stdDeviation="4"
                            result="blur"
                          ></feGaussianBlur>
                          <feColorMatrix
                            in="blur"
                            mode="matrix"
                            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7"
                            result="goo-12"
                          ></feColorMatrix>
                          <feBlend in="SourceGraphic" in2="goo-12"></feBlend>
                        </filter>
                      </defs>
                    </svg>
                  </div>

                  <label
                    className="w-fit cursor-pointer text-end"
                    htmlFor={String(course.key)}
                  >
                    {course.course_name}
                  </label>
                </div>
              ))}
            </div>
          </div>

          {/** Submit Button */}
          <button
            disabled={EditStudentCoursesLoading}
            className=" submit mt-6 w-full "
            type="submit"
          >
            {EditStudentCoursesLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              "Edit Student Courses"
            )}
          </button>
        </>
      )}
    </form>
  );
};

export default StudentAllCourses;
