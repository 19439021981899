import { Link, useParams } from "react-router-dom";
import HelmetTags from "../../../MainComponents/HelmetTags";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import { faFileSignature } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loader } from "../../../MainComponents";

function SingleExamPage() {
  const { ID } = useParams();

  //!---- get Exam info -------
  const [examInfoData, examInfoErrors, examInfoLoading] = useAxios(
    `${process.env.REACT_APP_STUDENT_GET_MATRIALE_PRACTISE_API}/${ID}`,
    "GET",
    ID
  );

  if (examInfoLoading) {
    return <Loader />;
  }

  if (examInfoErrors && typeof examInfoErrors === "string") {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <p>{examInfoErrors}</p>
      </div>
    );
  }
  return (
    <section className=" width mb-32 flex h-full flex-col items-center dark:bg-dark dark:text-light">
      <HelmetTags title="Exams | Dr.Tamer Ghonim "></HelmetTags>
      <div className="title-wrapper  mt-36">
        <h2
          className={`mb-20 text-center text-5xl font-bold transition-all duration-300 ease-linear  `}
        >
          {examInfoData?.data.title}
        </h2>
      </div>
      <div className="exam-details flex w-3/5 flex-col items-center gap-12 rounded-3xl bg-whitetext-primary p-16 md:w-full">
        <div className="img-wrapper h-96 w-full cursor-pointer overflow-hidden rounded-3xl">
          <img
            className="h-full w-full object-cover duration-300 hover:scale-125
          "
            src={examInfoData?.data.img}
            alt={examInfoData?.data.title}
          />
        </div>
        <h2 className="text-4xl font-bold">
          {examInfoData?.data.title || examInfoData?.data.name}
        </h2>
        <h3>{examInfoData?.data.description}</h3>
        <div className="time-price flex w-3/4 items-center justify-center gap-5">
          {examInfoData?.data.duration && (
            <p className="flex h-10 w-fit items-center justify-center gap-1 rounded-xl bg-white text-secondary px-3 font-semibold">
              <span>{examInfoData?.data.duration}</span>
              <span>min </span>
            </p>
          )}
        </div>

        <div className="quiz flex flex-col justify-between h-100 w-full border-t-2 mb-8 border-t-secondary  p-8 py-4 md:w-full md:border-r-0 md:border-t-2 md:border-t-secondary md:pt-10">
          <h2 className="my-8 w-full text-3xl font-semibold	 underline decoration-wavy underline-offset-8 ">
            <FontAwesomeIcon icon={faFileSignature} />
            <span className="ml-4">
              {examInfoData?.data?.is_exam === 0 ? "Quiz" : "Exam"}
            </span>
          </h2>
          {examInfoData?.data?.test_done === 1 && (
            <div className="student-quiz-info flex w-full flex-col gap-10">
              <h2 className="flex w-full items-center justify-between ">
                <span className="font-semibold">Degree</span>
                <span>{examInfoData?.data?.degree}</span>
              </h2>
              <h2 className="flex w-full items-center justify-between ">
                <span className="font-semibold">Average grades</span>
                <span>{examInfoData?.data?.average}</span>
              </h2>
              <h2 className="flex w-full items-center justify-between ">
                <span className="font-semibold">Grades in percentage</span>
                <span>{examInfoData?.data?.percentage}</span>
              </h2>
              <h2 className="flex w-full items-center justify-between ">
                <span className="font-semibold">
                  Student ranking on the Exam{" "}
                </span>
                <span>{examInfoData?.data?.rank}</span>
              </h2>
            </div>
          )}

          {examInfoData?.data?.test_done === 1 && (
            <div
              className="w-full examIframe mt-6"
              dangerouslySetInnerHTML={{
                __html: examInfoData?.data?.youtube_video_url,
              }}
            />
          )}

          {examInfoData?.data?.test_done === 1 && (
            <Link
              to={`/home/exams/modal-answer/${ID}`}
              className="w-full flex items-center justify-center mt-8 rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-white duration-300 hover:bg-white hover:text-accent active:scale-90"
            >
              Model Answer
            </Link>
          )}

          {examInfoData?.data?.test_done === 0 ? (
            <Link
              to={`/home/exams/solve-test/${ID}`}
              className="w-full flex items-center justify-center mt-8 rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-white duration-300 hover:bg-white hover:text-accent active:scale-90"
            >
              Start {examInfoData?.data?.is_exam === 0 ? "Quiz" : "Exam"}
            </Link>
          ) : null}
        </div>
      </div>
    </section>
  );
}

export default SingleExamPage;
