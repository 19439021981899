import React from "react";
import HelmetTags from "../../../MainComponents/HelmetTags";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import { StudentInsghtsChart } from "./StudentInsghtsChart";

const StudentInsghts = () => {
  const api = process.env.REACT_APP_GET_STUDENT_INSGHTS_API;

  const [allLecturesData, allLecturesErrors, allLecturesLoading] = useAxios(
    api,
    "GET",
    "GET"
  );

  return (
    <section className=" width  mb-32 h-full  dark:bg-dark dark:text-light">
      <HelmetTags title="All Domains | Dr.Tamer Ghonim"></HelmetTags>
      <div className="">
        <div className="my-20">
          <h2 className="w-full text-4xl font-bold md:text-center">
            All Domains
          </h2>
        </div>
        <StudentInsghtsChart data={allLecturesData?.data} />
        {/* {allLecturesData?.data?.length === 0 ? (
          <p>No lectures</p>
        ) : (
          allLecturesData?.data?.map((Card) => (
            <SingleCourse
              lecture={Card}
              key={Card.key}
              notSigned={landing ? true : false}
              myCourses={myCourses}
            />
          ))
        )} */}
      </div>
    </section>
  );
};

export default StudentInsghts;
