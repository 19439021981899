import useTable from "../../../../MainComponents/Hooks/useTable.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

function AdminDomains() {
  const { section } = useParams();

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags
        title={`Domains | Dr.Tamer Ghonim`}
        description="Domains | Dr.Tamer Ghonim"
        index
      ></HelmetTags>
      <div className="mb-20 flex flex-col items-end gap-20">
        <h2 className="w-full text-4xl font-bold md:text-center">Domains</h2>
      </div>
      <div className="flex gap-10">
        <Link
          to={`/admin/domains/est`}
          className="rounded-xl border-2 border-secondary bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 "
        >
          <FontAwesomeIcon className="mr-4" icon={faPlus} />
          Est
        </Link>
        <Link
          to={`/admin/domains/act`}
          className="rounded-xl border-2 border-secondary bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 "
        >
          <FontAwesomeIcon className="mr-4" icon={faPlus} />
          Act
        </Link>{" "}
        <Link
          to={`/admin/domains/sat`}
          className="rounded-xl border-2 border-secondary bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 "
        >
          <FontAwesomeIcon className="mr-4" icon={faPlus} />
          Digital Sat
        </Link>
      </div>
    </section>
  );
}

export default AdminDomains;
