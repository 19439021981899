import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";

const Question = ({
  state,
  dispatch,
  homework,
  exam,
  isParagraphe,
  placement,
}) => {
  const { ID } = useParams();

  const checkApi = homework
    ? process.env.REACT_APP_STUDENT_HOMEWORK_UPDATE_API
    : exam
      ? process.env.REACT_APP_STUDENT_TRIAL_EXAM_UPDATE_API
      : placement
        ? process.env.REACT_APP_STUDENT_UPDATE_PLACEMENT_TEST_ANSWER_API
        : process.env.REACT_APP_STUDENT_QUIZZ_UPDATE_API;

  const handleSelectAnswer = (answer, questionId, n) => {
    dispatch({
      type: "setAnsweredQuestions",
      payload: { id: questionId, order: answer, n },
    });
    async function fetchData() {
      try {
        let res = await axios.post(checkApi, {
          id: questionId,
          order: answer,
          lecture_id: ID,
          n: n,
        });
      } catch (error) {
        console.error("Error:", error);
        toast.error(error.response.data.message);
      }
    }

    fetchData();

    dispatch({
      type: "setIsSelectedAnswer",
      payload: { questionId, answer, n },
    });
  };

  return (
    <div
      className={`container flex-1 ${
        isParagraphe && "h-[90vh] w-full overflow-y-auto py-10 sm:px-10 px-3"
      }`}
    >
      {isParagraphe ? (
        <>
          {state.questions
            .filter(
              (question) => question.n_section === state.currentSectionIndex
            )
            .map((question, index) => (
              <div
                key={index}
                className="paragraphe singleQuestionWrapper rounded-2xl bg-white mb-4"
              >
                <div className="optionsQuestionWrapper current-question flex-col !gap-0">
                  <div className="categoryQuestionWrapper">
                    {`${index + 1}.`}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: question?.question,
                      }}
                    />
                  </div>
                  {/* <div className="current-question-degree w-full flex justify-end">
                    <p> {question?.degree} degree</p>
                  </div> */}
                </div>
                <div className="categoryAnswersWrapper current-question-answers">
                  {question?.answers.map((answer, i) => (
                    <div
                      key={i}
                      className={`categorySingleAnswer answer1Wrapper ${
                        question.selected_answer === answer.order && "selected"
                      }`}
                      onClick={() =>
                        handleSelectAnswer(
                          answer.order,
                          question.id,
                          question.n
                        )
                      }
                    >
                      <span className="number">{answer.order} </span>
                      <p dangerouslySetInnerHTML={{ __html: answer.answer }} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </>
      ) : (
        <>
          <div className="current-question flex-row">
            {`${state.currentSectionIndex}.`}
            <div
              dangerouslySetInnerHTML={{
                __html:
                  state.questions[state.currentSectionIndex - 1]?.question,
              }}
            />
          </div>

          {/* <div className="current-question-degree">
            <p>
              {" "}
              {state.questions[state.currentSectionIndex - 1]?.degree} degree
            </p>
          </div> */}

          <div className="current-question-answers">
            {state.questions[state.currentSectionIndex - 1]?.answers.map(
              (answer, i) => (
                <div
                  key={i}
                  className={`${
                    state.questions[state.currentSectionIndex - 1]
                      .selected_answer === answer.order && "selected"
                  }`}
                  onClick={() =>
                    handleSelectAnswer(
                      answer.order,
                      state.questions[state.currentSectionIndex - 1].id,
                      state.questions[state.currentSectionIndex - 1].n
                    )
                  }
                >
                  <span>{answer.order} </span>
                  <p dangerouslySetInnerHTML={{ __html: answer.answer }} />
                </div>
              )
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Question;
