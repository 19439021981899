import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import AddEditChooseQuestion from "../QuestionBank/AddEditChooseQuestion.jsx";
import AddEditEssayQuestion from "../QuestionBank/AddEditEssayQuestion.jsx";
import QuestionBankCategory from "../QuestionBank/QuestionBankCategory.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import Loader from "../../../../MainComponents/Loader.jsx";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import AdminQuizSection from "./AdminQuizSection.jsx";

function AdminPlacementTest({ placement }) {
  const { courseID } = useParams();
  const AuthState = useAuthState();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditInfo": {
        return {
          ...state,
          submitAddEditInfo: action.payload,
        };
      }
      case "setRefetchForGetInfo": {
        return {
          ...state,
          refetchForGetInfo: action.payload,
        };
      }
      case "setInfo": {
        return {
          ...state,
          info: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditInfo: {
      flag: "",
      dependency: false,
      data: {},
    },
    info: "",
    refetchForGetInfo: false,
  });
  const [values, setValues] = useState({});
  const [refetch, setRefetch] = useState(false);
  const [questionType, setQuestionType] = useState("choose");

  //!--------- get quiz / homework info for checking if there is a quiz or a homework has set previously  -------
  let InfoApi = `${process.env.REACT_APP_ADMIN_GET_PLACEMENT_TEST_API}/${courseID}`;
  const [getInfoSuccess, getInfoErrors, getInfoLoading] = useAxios(
    InfoApi,
    "GET",
    "GET",
    refetch
  );
  const staticNumber = getInfoSuccess?.n_section;
  // Create an array of the desired length
  const allSections = Array.from(
    { length: staticNumber },
    (_, index) => index + 1
  );
  useEffect(() => {
    if (getInfoSuccess) {
      console.log(getInfoSuccess);
      const { duration, visibility, success_rate } =
        getInfoSuccess?.data?.quiz_info;

      setValues({
        duration,
        visibility: visibility === 1 ? true : false,
        success_rate,
      });
    }
  }, [getInfoSuccess]);

  //!--------- add edit quiz / homework info  -------

  let addEditInfoApi =
    process.env.REACT_APP_ADMIN_ADD_UPDATE_PLACEMENT_TEST_API;

  const [AddEditInfoSuccess, AddEditInfoErrors, AddEditInfoLoading] = useAxios(
    addEditInfoApi,
    "POST",
    state.submitAddEditInfo.flag,
    state.submitAddEditInfo.dependency,
    state.submitAddEditInfo.data,
    true
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onTouched",
    values,
  });

  useEffect(() => {
    //!---add actions ----

    if (AddEditInfoSuccess) {
      setRefetch(!refetch);
    }
  }, [AddEditInfoSuccess]);

  function onSubmitAddEditInfo(data) {
    const { duration, visibility, success_rate } = data;
    let finalData = {
      duration: duration,
      visibility: visibility === true ? 1 : 0,
      success_rate,
      id: courseID,
    };
    dispatch({
      type: "setSubmitAddEditInfo",
      payload: {
        flag: "Quiz",
        dependency: !state.submitAddEditInfo.dependency,
        data: finalData,
      },
    });
  }

  if (getInfoLoading) {
    return <Loader />;
  }

  return (
    <section className="flex h-auto w-full flex-col gap-20 overflow-y-auto px-20 py-20 sm:px-10">
      <HelmetTags title={`Placement Test | Tamer `}></HelmetTags>
      <div className="add-quiz-homework flex w-full flex-col items-center gap-14 md:items-center">
        {placement ? (
          <>
            <div className="flex items-center gap-6 justify-center">
              {allSections?.map((section, index) => (
                <Link
                  to={`${index + 1}`}
                  className="rounded-xl border-2 border-secondary bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 "
                >
                  Section {index + 1}
                </Link>
              ))}
            </div>
          </>
        ) : null}
        <h2 className="w-full  text-3xl font-semibold">
          {getInfoSuccess ? "Edit Placement Test" : "Add Placement Test"}
        </h2>
        {/**
         * //!--- add exam duration or homework name -------
         */}
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("lecture_quizzes_permissions")
          )) && (
          <form
            method="post"
            noValidate
            onSubmit={handleSubmit(onSubmitAddEditInfo)}
            className="flex w-3/4  flex-col  justify-start gap-10 rounded-3xl   p-16 bg-white shadow-md md:w-full"
          >
            <div className="flex  w-full items-center justify-evenly gap-16 md:flex-col-reverse md:gap-10 ">
              <div
                //!--duration--
                className=" duration flex  w-1/2 flex-col justify-center gap-2 md:w-full"
              >
                <label className="w-fit truncate" htmlFor="duration">
                  Duration of the exam in minutes
                </label>
                <input
                  className="signin-inputs signin-input w-full "
                  type="number"
                  min={1}
                  id="seconds"
                  max={59}
                  placeholder="min"
                  name="seconds"
                  autoComplete="on"
                  {...register("duration", {
                    valueAsNumber: true,
                    min: 1,
                    required: true,
                  })}
                />

                {errors.duration && (
                  <>
                    <p className="text-[12px] text-blue-900 ">
                      {errors.duration?.type === "required" &&
                        "Please fill out this field"}
                      {errors.duration?.type === "min" &&
                        "من فضلك ادخل رقم اكبر من واحد"}
                    </p>
                  </>
                )}
                {
                  //!-------server errors -----

                  AddEditInfoErrors &&
                    AddEditInfoErrors?.response?.data?.errors?.duration && (
                      <p className="w-full  text-[12px] text-blue-900  ">
                        {AddEditInfoErrors?.response?.data?.errors?.duration}
                      </p>
                    )
                }
              </div>
              <div
                //!--duration--
                className="success_rate flex  w-1/2 flex-col justify-center gap-2 md:w-full"
              >
                <label className="w-fit truncate" htmlFor="success_rate">
                  Success Rate
                </label>
                <input
                  className="signin-inputs signin-input w-full "
                  type="number"
                  min={1}
                  id="success_rate"
                  max={100}
                  placeholder="%"
                  name="success_rate"
                  autoComplete="on"
                  {...register("success_rate", {
                    valueAsNumber: true,
                    min: 1,
                    required: true,
                  })}
                />

                {errors.success_rate && (
                  <>
                    <p className="text-[12px] text-blue-900 ">
                      {errors.success_rate?.type === "required" &&
                        "Please fill out this field"}
                    </p>
                  </>
                )}
                {
                  //!-------server errors -----

                  AddEditInfoErrors &&
                    AddEditInfoErrors?.response?.data?.errors?.duration && (
                      <p className="w-full  text-[12px] text-blue-900  ">
                        {AddEditInfoErrors?.response?.data?.errors?.duration}
                      </p>
                    )
                }
              </div>
              <div className=" visibility flex w-1/2 flex-col items-start justify-center gap-2 md:w-full ">
                <div className="flex w-full items-center justify-start gap-10">
                  <label className="visibility-switch">
                    <input
                      className=""
                      id="visibility"
                      name="visibility"
                      {...register("visibility", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <div>
                    <label
                      className="w-full cursor-pointer truncate"
                      htmlFor="visibility"
                    >
                      Visibility
                    </label>
                  </div>
                </div>

                {errors.visibility && (
                  <p className="text-[12px] text-blue-900 ">
                    {errors.visibility.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  AddEditInfoErrors &&
                    AddEditInfoErrors?.response?.data?.errors?.visibility && (
                      <p className="w-full  text-[12px] text-blue-900  ">
                        {AddEditInfoErrors?.response?.data?.errors?.visibility}
                      </p>
                    )
                }
              </div>
            </div>

            {/** submit */}

            <button
              disabled={
                !isValid || (AddEditInfoLoading && state.submitAddEditInfo.flag)
              }
              className=" submit mt-6 w-full "
              type="submit"
            >
              {AddEditInfoLoading && state.submitAddEditInfo.flag ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <p>Edit Info</p>
              )}
            </button>
          </form>
        )}
      </div>

      <AdminQuizSection placement />

      {/* {getInfoSuccess?.is_found === 1 ? ( */}
      <div className="custom-question  w-full ">
        {/* <h2 className=" text-3xl font-semibold">Add a custom question </h2> */}
        {/* <div className="custom-question-options mt-14 flex justify-start gap-10 md:justify-center">
              <button
                onClick={() => setQuestionType("choose")}
                className={`  rounded-2xl border-2  border-secondary  px-3 py-1 text-center font-semibold  duration-300 hover:bg-secondary hover:text-light  active:scale-90 ${
                  questionType === "choose" ? "bg-secondary text-light" : ""
                }`}
              >
                Choices question
              </button>
              <button
                onClick={() => setQuestionType("essay")}
                className={`  rounded-2xl border-2 border-secondary  px-3 py-1 text-center font-semibold  duration-300 hover:bg-secondary hover:text-light active:scale-90 ${
                  questionType === "essay" ? "bg-secondary text-light" : ""
                }`}
              >
                Essay question
              </button>
            </div> */}
        {/* <AddEditChooseQuestion
            type="placementTest"
            id={getInfoSuccess?.data?.key}
            refetch={refetch}
            setRefetch={setRefetch}
          /> */}
      </div>
      {/* {getInfoSuccess?.data?.placement_questions?.length > 0 && (
          <div className="all-custom-questions w-full ">
            <h2 className=" text-3xl font-semibold"> Custome Questions </h2>
            <QuestionBankCategory
              type={"quiz"}
              ID={getInfoSuccess?.data?.key}
              QuizQuestionsData={getInfoSuccess?.data?.placement_questions}
              setRefetchQuiz={setRefetch}
              refetchQuiz={refetch}
            />
          </div>
        )} */}
      {/* ) : null} */}
    </section>
  );
}

export default AdminPlacementTest;
