import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  elements: {
    bar: {
      borderWidth: 0,
      borderRadius: 4,
    },
  },
  scales: {
    x: {
      grid: {
        display: false, // Set display to false to remove vertical grid lines
      },
      beginAtZero: true,
      ticks: {
        color: "#AFAFAF", // Set the color of the y-axis labels
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        color: "#F6F6F6",
      },
      ticks: {
        color: "#AFAFAF",
      },
    },
  },
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: false,
      text: "",
    },
  },
};

export const StudentInsghtsChart = ({ data }) => {
  const labels = data?.map((record) => record.domain) || [];

  const finalData = {
    labels,
    datasets: [
      {
        label: "Count",
        data: data?.map((record) => record.errors_count) || [],
        borderColor: "#2e30ff",
        backgroundColor: "#2e30ff",
        barThickness: 30,
      },
    ],
  };

  return (
    <div className="w-full">
      <Bar options={options} data={finalData} height="100px" />
    </div>
  );
};
