import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor as ClassicEditor } from "ckeditor5-custom-build/build/ckeditor";
import { toast } from "react-hot-toast";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function AddEditChooseQuestion({
  edit,
  type,
  id,
  lectureQuestion,
  refetch,
  setRefetch,
  isSection,
  placement,
  questionType,
  typeclass,
}) {
  const { questionID, examID, sectionNumber, courseID } = useParams();

  let QuestionDataApi = lectureQuestion
    ? `${process.env.REACT_APP_QUIZ_HOMEWORK_QUESTION_INFO_API}${questionID}`
    : `${process.env.REACT_APP_QUESTION_INFO_API}${questionID}`;
  const [QuestionDataSuccess, QuestionErrors, loading] = useAxios(
    QuestionDataApi,
    "GET",
    edit
  );

  const [question, setQuestion] = useState(" ");
  const [answer1, setAnswer1] = useState("");
  const [answer2, setAnswer2] = useState("");
  const [answer3, setAnswer3] = useState("");
  const [answer4, setAnswer4] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [correct_answer, setCorrectAnswer] = useState("");

  const [domain, setDomain] = useState("");
  const [skill, setSkill] = useState("");
  const [degree, setDegree] = useState("");

  useEffect(() => {
    if (question !== "") {
      setErrors("");
    } else if (answer1 !== "") {
      setErrors("");
    } else if (answer2 !== "") {
      setErrors("");
    } else if (answer3 !== "") {
      setErrors("");
    } else if (answer4 !== "") {
      setErrors("");
    } else if (difficulty !== "") {
      setErrors("");
    } else if (correct_answer !== "") {
      setErrors("");
    } else if (degree !== "") {
      setErrors("");
    }
  }, [
    question,
    answer1,
    answer2,
    answer3,
    answer4,
    difficulty,
    correct_answer,
    degree,
  ]);

  const [errors, setErrors] = useState("");
  const navigate = useNavigate();

  const [submitQuestion, setSubmitQuestion] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [getAllDomainsSuccess, getAllDomainsErrors, getAllDomainsLoading] =
    useAxios(
      `${process.env.REACT_APP_GET_ALL_DOMAINS_API}/${questionType}`,
      "GET",
      "GET"
    );

  const [getAllSkillsSuccess, getAllSkillsErrors, getAllSkillsLoading] =
    useAxios(
      `${process.env.REACT_APP_GET_ALL_SKILLS_API}/${domain}`,
      "GET",
      domain,
      domain
    );

  const api = edit
    ? process.env.REACT_APP_ADMIN_TRIAL_EXAM_EDIT_CHOOSE_QUESTION_API
    : !edit && placement
      ? process.env.REACT_APP_ADMIN_ADD_QUESTION_PLACEMENT_TEST_API
      : process.env.REACT_APP_ADMIN_TRIAL_EXAM_ADD_CHOOSE_QUESTION_API;

  const [addEditQuestionSuccess, addEditQuestionErrors, submitLoading] =
    useAxios(
      api,
      "POST",
      submitQuestion.flag,
      submitQuestion.dependency,
      submitQuestion.data,
      true
    );

  useEffect(() => {
    if (QuestionDataSuccess) {
      setQuestion(QuestionDataSuccess.data.question || "");
      setAnswer1(QuestionDataSuccess.data.answer1);
      setAnswer2(QuestionDataSuccess.data.answer2);
      setAnswer3(QuestionDataSuccess.data.answer3);
      setAnswer4(QuestionDataSuccess.data.answer4);
      setDifficulty(QuestionDataSuccess.data.difficulty);
      setCorrectAnswer(QuestionDataSuccess.data.correct_answer);
      setDegree(QuestionDataSuccess.data?.degree);
      setDomain(QuestionDataSuccess.data?.domain);
      setSkill(QuestionDataSuccess.data?.skill);
    }
  }, [QuestionDataSuccess]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (answer1 === "") {
      setErrors("answer1");
      toast.error("Please fill out all the required information");
    } else if (answer2 === "") {
      setErrors("answer2");
      toast.error("Please fill out all the required information");
    } else if (answer3 === "") {
      setErrors("answer3");
      toast.error("Please fill out all the required information");
    } else if (answer4 === "") {
      setErrors("answer4");
      toast.error("Please fill out all the required information");
    } else if (difficulty === "" && !type) {
      setErrors("difficulty");
      toast.error("Please fill out all the required information");
    } else if (correct_answer === "") {
      setErrors("correct_answer");
      toast.error("Please fill out all the required information");
    } else if (degree === "" || Number(degree) < 1) {
      setErrors("degree");
      toast.error("Please enter a number greater than zero");
    } else {
      let data = {
        question: question,
        answer1: answer1,
        answer2: answer2,
        answer3: answer3,
        answer4: answer4,
        correct_answer: correct_answer,
        difficulty: difficulty,
        degree: degree,
        exam_id: examID,
        id: questionID ? questionID : null,
        n_section: sectionNumber,
        course_id: courseID ? courseID : null,
        skill: skill,
        domain: domain,
      };

      setSubmitQuestion({
        flag: "addEditQuestionRequest",
        dependency: !submitQuestion.dependency,
        data: data,
      });
    }
  };

  const handleChangeDomain = () => {};
  useEffect(() => {
    //! reset the form on success submit

    if (addEditQuestionSuccess) {
      setQuestion("");
      setAnswer1("");
      setAnswer2("");
      setAnswer3("");
      setAnswer4("");
      setDifficulty("");
      setCorrectAnswer("");
      setDegree("");
      setErrors("");
      setDomain("");
      setSkill("");
      type ? setRefetch(!refetch) : navigate(-1);
    }
  }, [addEditQuestionSuccess]);

  return (
    <section className="flex h-auto w-full flex-col items-center dark:bg-dark dark:text-light">
      {!type && (
        <HelmetTags title="Question Bank | Dr.Tamer Ghonim"></HelmetTags>
      )}
      <form
        method="post"
        onSubmit={handleSubmit}
        className="form-container my-20 flex flex-col items-center "
      >
        {isSection ? (
          <div className=" question mb-10 flex w-[900px] flex-col items-end gap-2 xsl:w-[700px] amd:w-[500px] sm:w-[300px]">
            <div className="mb-3 w-full flex justify-between items-center gap-5 md:flex-col md:items-center">
              <h2 className="mb-3 text-3xl font-semibold">Section</h2>
            </div>
            <CKEditor
              config={{
                toolbar: {
                  items: [
                    "undo",
                    "redo",
                    "|",
                    "imageInsert",
                    "|",
                    "heading",
                    "bold",
                    "italic",
                    "strikethrough",
                    "underline",
                    "link",
                    "blockQuote",
                    "removeFormat",
                    "|",
                    "selectAll",
                    "specialCharacters",
                    "superscript",
                    "subscript",
                    "style",
                    "|",
                    "bulletedList",
                    "numberedList",
                    "outdent",
                    "indent",
                    "|",
                    "mediaEmbed",
                    "insertTable",
                    "|",
                    "fontFamily",
                    "fontColor",
                    "fontBackgroundColor",
                    "fontSize",
                    "highlight",
                    "|",
                    "horizontalLine",
                    "pageBreak",
                    "findAndReplace",
                    "restrictedEditingException",
                    "textPartLanguage",
                    "-",
                  ],
                  shouldNotGroupWhenFull: true,
                },
                language: "en",
                image: {
                  toolbar: [
                    "imageTextAlternative",
                    "toggleImageCaption",
                    "imageStyle:inline",
                    "imageStyle:block",
                    "imageStyle:side",
                  ],
                },
                table: {
                  contentToolbar: [
                    "tableColumn",
                    "tableRow",
                    "mergeTableCells",
                    "tableCellProperties",
                    "tableProperties",
                  ],
                },
              }}
              editor={ClassicEditor}
              data={question}
              onChange={(event, editor) => {
                const data = editor.getData();
                setQuestion(data);
              }}
            />
            {errors === "question" && (
              <p className="text-[12px] text-blue-900 ">
                Please fill out this field
              </p>
            )}
            {
              //!-------server errors -----

              addEditQuestionErrors &&
                addEditQuestionErrors?.response?.data?.errors?.question && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {addEditQuestionErrors?.response?.data?.errors?.question[0]}
                  </p>
                )
            }
          </div>
        ) : (
          <>
            <h2 className="my-20 text-center text-3xl font-bold">
              {edit
                ? "Please fill in the information to modify the question."
                : "Please fill in the information to add the question."}
            </h2>
            {/**
             * //!------question-----
             */}
            <div className=" question mb-10 flex w-[900px] flex-col items-end gap-2 xsl:w-[700px] amd:w-[500px] sm:w-[300px]">
              <div className="mb-3 w-full flex flex-row-reverse justify-between items-center gap-5 md:flex-col md:items-center">
                <div className=" bubble flex w-1/2 flex-col items-start justify-center gap-2 md:w-full ">
                  <div className="flex w-full items-center justify-end gap-10">
                    <label className="visibility-switch order-2">
                      <input
                        className=""
                        id="bubble"
                        name="bubble"
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setAnswer1("<h2></h2> <h2>A</h2>");
                            setAnswer2("<h2></h2> <h2>B</h2>");
                            setAnswer3("<h2></h2> <h2>C</h2>");
                            setAnswer4("<h2></h2> <h2>D</h2>");
                          } else {
                            setAnswer1("");
                            setAnswer2("");
                            setAnswer3("");
                            setAnswer4("");
                          }
                        }}
                      />
                      <span className="visibility-slider">
                        <svg
                          className="slider-icon"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <path fill="none" d="m4 16.5 8 8 16-16"></path>
                        </svg>
                      </span>
                    </label>
                    <div>
                      <label
                        className="w-full cursor-pointer truncate order-1"
                        htmlFor="bubble"
                      >
                        Bubble Sheet
                      </label>
                    </div>
                  </div>
                </div>
                <h2 className="mb-3 text-3xl font-semibold">Question</h2>
              </div>
              <CKEditor
                config={{
                  toolbar: {
                    items: [
                      "undo",
                      "redo",
                      "|",
                      "imageInsert",
                      "|",
                      "heading",
                      "bold",
                      "italic",
                      "strikethrough",
                      "underline",
                      "link",
                      "blockQuote",
                      "removeFormat",
                      "|",
                      "selectAll",
                      "specialCharacters",
                      "superscript",
                      "subscript",
                      "style",
                      "|",
                      "bulletedList",
                      "numberedList",
                      "outdent",
                      "indent",
                      "|",
                      "mediaEmbed",
                      "insertTable",
                      "|",
                      "fontFamily",
                      "fontColor",
                      "fontBackgroundColor",
                      "fontSize",
                      "highlight",
                      "|",
                      "horizontalLine",
                      "pageBreak",
                      "findAndReplace",
                      "restrictedEditingException",
                      "textPartLanguage",
                      "-",
                    ],
                    shouldNotGroupWhenFull: true,
                  },
                  language: "en",
                  image: {
                    toolbar: [
                      "imageTextAlternative",
                      "toggleImageCaption",
                      "imageStyle:inline",
                      "imageStyle:block",
                      "imageStyle:side",
                    ],
                  },
                  table: {
                    contentToolbar: [
                      "tableColumn",
                      "tableRow",
                      "mergeTableCells",
                      "tableCellProperties",
                      "tableProperties",
                    ],
                  },
                }}
                editor={ClassicEditor}
                data={question}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setQuestion(data);
                }}
              />
              {errors === "question" && (
                <p className="text-[12px] text-blue-900 ">
                  Please fill out this field
                </p>
              )}
              {
                //!-------server errors -----

                addEditQuestionErrors &&
                  addEditQuestionErrors?.response?.data?.errors?.question && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {
                        addEditQuestionErrors?.response?.data?.errors
                          ?.question[0]
                      }
                    </p>
                  )
              }
            </div>
            {/**
             * //!------answer 1-----
             */}
            <div className=" answer1 my-10 flex w-[900px] flex-col gap-2 xsl:w-[700px] amd:w-[500px] sm:w-[300px]">
              <h2 className="mb-3 text-3xl font-semibold">First answer</h2>
              <CKEditor
                config={{
                  toolbar: {
                    items: [
                      "undo",
                      "redo",
                      "|",
                      "imageInsert",
                      "|",
                      "heading",
                      "bold",
                      "italic",
                      "strikethrough",
                      "underline",
                      "link",
                      "blockQuote",
                      "removeFormat",
                      "|",
                      "selectAll",
                      "specialCharacters",
                      "superscript",
                      "subscript",
                      "style",
                      "|",
                      "bulletedList",
                      "numberedList",
                      "outdent",
                      "indent",
                      "|",
                      "mediaEmbed",
                      "insertTable",
                      "|",
                      "fontFamily",
                      "fontColor",
                      "fontBackgroundColor",
                      "fontSize",
                      "highlight",
                      "|",
                      "horizontalLine",
                      "pageBreak",
                      "findAndReplace",
                      "restrictedEditingException",
                      "textPartLanguage",
                      "-",
                    ],
                    shouldNotGroupWhenFull: true,
                  },
                  language: "en",
                  image: {
                    toolbar: [
                      "imageTextAlternative",
                      "toggleImageCaption",
                      "imageStyle:inline",
                      "imageStyle:block",
                      "imageStyle:side",
                    ],
                  },
                  table: {
                    contentToolbar: [
                      "tableColumn",
                      "tableRow",
                      "mergeTableCells",
                      "tableCellProperties",
                      "tableProperties",
                    ],
                  },
                }}
                editor={ClassicEditor}
                data={answer1}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setAnswer1(data);
                }}
              />
              {errors === "answer1" && (
                <p className="text-[12px] text-blue-900 ">
                  Please fill out this field
                </p>
              )}
              {
                //!-------server errors -----

                addEditQuestionErrors &&
                  addEditQuestionErrors?.response?.data?.errors?.answer1 && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {
                        addEditQuestionErrors?.response?.data?.errors
                          ?.answer1[0]
                      }
                    </p>
                  )
              }
            </div>
            {/**
             * //!------answer 2-----
             */}
            <div className=" answer1 my-10 flex w-[900px] flex-col gap-2 xsl:w-[700px] amd:w-[500px] sm:w-[300px]">
              <h2 className="mb-3 text-3xl font-semibold">Second answer</h2>
              <CKEditor
                config={{
                  toolbar: {
                    items: [
                      "undo",
                      "redo",
                      "|",
                      "imageInsert",
                      "|",
                      "heading",
                      "bold",
                      "italic",
                      "strikethrough",
                      "underline",
                      "link",
                      "blockQuote",
                      "removeFormat",
                      "|",
                      "selectAll",
                      "specialCharacters",
                      "superscript",
                      "subscript",
                      "style",
                      "|",
                      "bulletedList",
                      "numberedList",
                      "outdent",
                      "indent",
                      "|",
                      "mediaEmbed",
                      "insertTable",
                      "|",
                      "fontFamily",
                      "fontColor",
                      "fontBackgroundColor",
                      "fontSize",
                      "highlight",
                      "|",
                      "horizontalLine",
                      "pageBreak",
                      "findAndReplace",
                      "restrictedEditingException",
                      "textPartLanguage",
                      "-",
                    ],
                    shouldNotGroupWhenFull: true,
                  },
                  language: "en",
                  image: {
                    toolbar: [
                      "imageTextAlternative",
                      "toggleImageCaption",
                      "imageStyle:inline",
                      "imageStyle:block",
                      "imageStyle:side",
                    ],
                  },
                  table: {
                    contentToolbar: [
                      "tableColumn",
                      "tableRow",
                      "mergeTableCells",
                      "tableCellProperties",
                      "tableProperties",
                    ],
                  },
                }}
                editor={ClassicEditor}
                data={answer2}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setAnswer2(data);
                }}
              />
              {errors === "answer2" && (
                <p className="text-[12px] text-blue-900 ">
                  Please fill out this field
                </p>
              )}
              {
                //!-------server errors -----

                addEditQuestionErrors &&
                  addEditQuestionErrors?.response?.data?.errors?.answer2 && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {
                        addEditQuestionErrors?.response?.data?.errors
                          ?.answer2[0]
                      }
                    </p>
                  )
              }
            </div>
            {/**
             * //!------answer 3-----
             */}
            <div className=" answer1 my-10 flex w-[900px] flex-col gap-2 xsl:w-[700px] amd:w-[500px] sm:w-[300px]">
              <h2 className="mb-3 text-3xl font-semibold">Third answer</h2>
              <CKEditor
                config={{
                  toolbar: {
                    items: [
                      "undo",
                      "redo",
                      "|",
                      "imageInsert",
                      "|",
                      "heading",
                      "bold",
                      "italic",
                      "strikethrough",
                      "underline",
                      "link",
                      "blockQuote",
                      "removeFormat",
                      "|",
                      "selectAll",
                      "specialCharacters",
                      "superscript",
                      "subscript",
                      "style",
                      "|",
                      "bulletedList",
                      "numberedList",
                      "outdent",
                      "indent",
                      "|",
                      "mediaEmbed",
                      "insertTable",
                      "|",
                      "fontFamily",
                      "fontColor",
                      "fontBackgroundColor",
                      "fontSize",
                      "highlight",
                      "|",
                      "horizontalLine",
                      "pageBreak",
                      "findAndReplace",
                      "restrictedEditingException",
                      "textPartLanguage",
                      "-",
                    ],
                    shouldNotGroupWhenFull: true,
                  },
                  language: "en",
                  image: {
                    toolbar: [
                      "imageTextAlternative",
                      "toggleImageCaption",
                      "imageStyle:inline",
                      "imageStyle:block",
                      "imageStyle:side",
                    ],
                  },
                  table: {
                    contentToolbar: [
                      "tableColumn",
                      "tableRow",
                      "mergeTableCells",
                      "tableCellProperties",
                      "tableProperties",
                    ],
                  },
                }}
                editor={ClassicEditor}
                data={answer3}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setAnswer3(data);
                }}
              />
              {errors === "answer3" && (
                <p className="text-[12px] text-blue-900 ">
                  Please fill out this field
                </p>
              )}
              {
                //!-------server errors -----

                addEditQuestionErrors &&
                  addEditQuestionErrors?.response?.data?.errors?.answer3 && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {
                        addEditQuestionErrors?.response?.data?.errors
                          ?.answer3[0]
                      }
                    </p>
                  )
              }
            </div>
            {/**
             * //!------answer 4-----
             */}
            <div className=" answer1 my-10 flex w-[900px] flex-col gap-2 xsl:w-[700px] amd:w-[500px] sm:w-[300px]">
              <h2 className="mb-3 text-3xl font-semibold">Fourth answer</h2>
              <CKEditor
                config={{
                  toolbar: {
                    items: [
                      "undo",
                      "redo",
                      "|",
                      "imageInsert",
                      "|",
                      "heading",
                      "bold",
                      "italic",
                      "strikethrough",
                      "underline",
                      "link",
                      "blockQuote",
                      "removeFormat",
                      "|",
                      "selectAll",
                      "specialCharacters",
                      "superscript",
                      "subscript",
                      "style",
                      "|",
                      "bulletedList",
                      "numberedList",
                      "outdent",
                      "indent",
                      "|",
                      "mediaEmbed",
                      "insertTable",
                      "|",
                      "fontFamily",
                      "fontColor",
                      "fontBackgroundColor",
                      "fontSize",
                      "highlight",
                      "|",
                      "horizontalLine",
                      "pageBreak",
                      "findAndReplace",
                      "restrictedEditingException",
                      "textPartLanguage",
                      "-",
                    ],
                    shouldNotGroupWhenFull: true,
                  },
                  language: "en",
                  image: {
                    toolbar: [
                      "imageTextAlternative",
                      "toggleImageCaption",
                      "imageStyle:inline",
                      "imageStyle:block",
                      "imageStyle:side",
                    ],
                  },
                  table: {
                    contentToolbar: [
                      "tableColumn",
                      "tableRow",
                      "mergeTableCells",
                      "tableCellProperties",
                      "tableProperties",
                    ],
                  },
                }}
                editor={ClassicEditor}
                data={answer4}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setAnswer4(data);
                }}
              />
              {errors === "answer4" && (
                <p className="text-[12px] text-blue-900 ">
                  Please fill out this field
                </p>
              )}
              {
                //!-------server errors -----

                addEditQuestionErrors &&
                  addEditQuestionErrors?.response?.data?.errors?.answer4 && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {
                        addEditQuestionErrors?.response?.data?.errors
                          ?.answer4[0]
                      }
                    </p>
                  )
              }
            </div>
            {/**
             * //!---------right answer & difficulty & degree
             */}
            <div className="flex w-full items-start justify-between gap-10 md:flex-col-reverse my-10">
              {/**
               * //!------right answer -----
               */}
              <div
                className={` flex w-1/2 flex-col justify-center gap-2  md:w-full `}
              >
                <label className="w-full truncate" htmlFor="correct_answer">
                  Correct answer
                </label>
                <select
                  value={correct_answer}
                  onChange={(e) => setCorrectAnswer(e.target.value)}
                  name="correct_answer"
                  id="correct_answer"
                >
                  <option hidden value=""></option>
                  <option value="answer1">First answer</option>
                  <option value="answer2">Second answer</option>
                  <option value="answer3">Third answer</option>
                  <option value="answer4">Fourth answer</option>
                </select>

                {errors === "correct_answer" && (
                  <p className="text-[12px] text-blue-900 ">
                    Please fill out this field
                  </p>
                )}
                {
                  //!-------server errors -----

                  addEditQuestionErrors &&
                    addEditQuestionErrors?.response?.data?.errors
                      ?.correct_answer && (
                      <p className="w-full  text-[12px] text-blue-900  ">
                        {
                          addEditQuestionErrors?.response?.data?.errors
                            ?.correct_answer[0]
                        }
                      </p>
                    )
                }
              </div>
              <div className=" flex w-1/2  flex-col items-end justify-center gap-2 md:w-full">
                <label className="w-full truncate" htmlFor="degree">
                  Degree
                </label>
                <input
                  className="signin-inputs w-full "
                  type="number"
                  inputMode="numeric"
                  min={1}
                  value={degree}
                  onChange={(e) => setDegree(e.target.value)}
                  id="degree"
                  placeholder="000"
                  name="degree"
                  autoComplete="on"
                />

                {errors === "degree" && (
                  <p className="text-[12px] text-blue-900 ">
                    Please fill out this field
                  </p>
                )}
                {
                  //!-------server errors -----

                  addEditQuestionErrors &&
                    addEditQuestionErrors?.response?.data?.errors?.degree && (
                      <p className="text-[12px] text-blue-900 ">
                        {
                          addEditQuestionErrors?.response?.data?.errors
                            ?.degree[0]
                        }
                      </p>
                    )
                }
              </div>
            </div>
            {!placement &&
            QuestionDataSuccess?.data?.domain !== null &&
            getAllDomainsSuccess?.data ? (
              <>
                {console.log(type, QuestionDataSuccess?.data.domain)}
                {/* {QuestionDataSuccess?.data?.domain && ( */}
                <div className="flex w-full items-start justify-between gap-10 md:flex-col-reverse my-10">
                  {/**
                   * //!------right answer -----
                   */}
                  <div
                    className={` flex w-1/2 flex-col justify-center gap-2  md:w-full `}
                  >
                    <label className="w-full truncate" htmlFor="domain">
                      Domain
                    </label>
                    <select
                      value={domain}
                      onChange={(e) => {
                        handleChangeDomain(e.target.value);
                        setDomain(e.target.value);
                        setSkill("");
                      }}
                      name="domain"
                      id="domain"
                    >
                      <option hidden value=""></option>
                      {getAllDomainsSuccess?.data?.map((domain) => (
                        <option value={domain.key}>{domain.name}</option>
                      ))}
                    </select>

                    {errors === "domain" && (
                      <p className="text-[12px] text-blue-900 ">
                        Please fill out this field
                      </p>
                    )}
                    {
                      //!-------server errors -----

                      addEditQuestionErrors &&
                        addEditQuestionErrors?.response?.data?.errors
                          ?.domain && (
                          <p className="w-full  text-[12px] text-blue-900  ">
                            {
                              addEditQuestionErrors?.response?.data?.errors
                                ?.domain[0]
                            }
                          </p>
                        )
                    }
                  </div>
                  {getAllSkillsSuccess && (
                    <div
                      className={` flex w-1/2 flex-col justify-center gap-2  md:w-full `}
                    >
                      <label className="w-full truncate" htmlFor="skill">
                        Skills
                      </label>
                      <select
                        value={skill}
                        onChange={(e) => {
                          setSkill(e.target.value);
                        }}
                        name="skill"
                        id="skill"
                      >
                        <option hidden value=""></option>
                        {getAllSkillsSuccess?.data?.map((skill) => (
                          <option value={skill.key}>{skill.name}</option>
                        ))}
                      </select>

                      {errors === "skill" && (
                        <p className="text-[12px] text-blue-900 ">
                          Please fill out this field
                        </p>
                      )}
                      {
                        //!-------server errors -----

                        addEditQuestionErrors &&
                          addEditQuestionErrors?.response?.data?.errors
                            ?.skill && (
                            <p className="w-full  text-[12px] text-blue-900  ">
                              {
                                addEditQuestionErrors?.response?.data?.errors
                                  ?.skill[0]
                              }
                            </p>
                          )
                      }
                    </div>
                  )}
                </div>
                {/* )} */}
              </>
            ) : null}
          </>
        )}

        <button
          disabled={submitLoading && submitQuestion.flag}
          className=" submit mt-6 !w-1/2 "
          type="submit"
        >
          {submitLoading && submitQuestion.flag ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <p> {edit ? "Edit" : "Add"}</p>
          )}
        </button>
      </form>
    </section>
  );
}

export default AddEditChooseQuestion;
