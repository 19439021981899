import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor as ClassicEditor } from "ckeditor5-custom-build/build/ckeditor";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function AdminAddEditParagraphe({
  refetch,
  setRefetch,
  getInfoSuccess,
  placement,
}) {
  const { examID, courseID, sectionNumber } = useParams();

  const [question, setParagraph] = useState("");

  useEffect(() => {
    if (question !== "") {
      setErrors("");
    }
  }, [question]);

  const [errors, setErrors] = useState("");

  const [submitParagraph, setSubmitParagraph] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [addEditParagraphSuccess, addEditParagraphErrors, submitLoading] =
    useAxios(
      process.env.REACT_APP_ADD_PRACTISES_PARAGRAPHE_MATRIALS_API,
      "POST",
      submitParagraph.flag,
      submitParagraph.dependency,
      submitParagraph.data,
      true
    );

  useEffect(() => {
    if (getInfoSuccess?.data?.paragraph) {
      setParagraph(getInfoSuccess?.data?.paragraph);
    }
  }, [getInfoSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (question === "") {
      setErrors("question");
      toast.error("Please fill out all the required information");
    } else {
      const data = {
        paragraph: question,
        exam_id: placement ? null : examID,
        course_id: placement ? courseID : null,
        n_section: sectionNumber,
      };
      setSubmitParagraph({
        flag: "addEditParagraphRequest",
        dependency: !submitParagraph.dependency,
        data: data,
      });
    }
  };
  useEffect(() => {
    //! reset the form on success submit

    if (addEditParagraphSuccess) {
      setParagraph("");
      setErrors("");
      setRefetch(!refetch);
    }
  }, [addEditParagraphSuccess, refetch, setRefetch]);

  return (
    <section className="flex h-auto w-full flex-col items-center dark:bg-dark dark:text-light">
      <form
        method="post"
        onSubmit={handleSubmit}
        className="form-container mt-20 flex flex-col items-center "
      >
        <>
          {/**
           * //!------question-----
           */}
          <div className=" question mb-10 flex w-[900px] flex-col items-end gap-2 xsl:w-[700px] amd:w-[500px] sm:w-[300px]">
            <div className="mb-3 w-full flex  items-center gap-5 md:flex-col md:items-center">
              <h2 className="mb-3 text-3xl font-semibold">
                {getInfoSuccess?.data?.paragraph
                  ? "Update Paragraphe"
                  : "Add Paragraphe"}
              </h2>
            </div>
            <CKEditor
              config={{
                toolbar: {
                  items: [
                    "undo",
                    "redo",
                    "|",
                    "imageInsert",
                    "|",
                    "heading",
                    "bold",
                    "italic",
                    "strikethrough",
                    "underline",
                    "link",
                    "blockQuote",
                    "removeFormat",
                    "|",
                    "selectAll",
                    "specialCharacters",
                    "superscript",
                    "subscript",
                    "style",
                    "|",
                    "bulletedList",
                    "numberedList",
                    "outdent",
                    "indent",
                    "|",
                    "mediaEmbed",
                    "insertTable",
                    "|",
                    "fontFamily",
                    "fontColor",
                    "fontBackgroundColor",
                    "fontSize",
                    "highlight",
                    "|",
                    "horizontalLine",
                    "pageBreak",
                    "findAndReplace",
                    "restrictedEditingException",
                    "textPartLanguage",
                    "-",
                  ],
                  shouldNotGroupWhenFull: true,
                },
                language: "en",
                image: {
                  toolbar: [
                    "imageTextAlternative",
                    "toggleImageCaption",
                    "imageStyle:inline",
                    "imageStyle:block",
                    "imageStyle:side",
                  ],
                },
                table: {
                  contentToolbar: [
                    "tableColumn",
                    "tableRow",
                    "mergeTableCells",
                    "tableCellProperties",
                    "tableProperties",
                  ],
                },
              }}
              editor={ClassicEditor}
              data={question}
              onChange={(event, editor) => {
                const data = editor.getData();
                setParagraph(data);
              }}
            />
            {errors === "question" && (
              <p className="text-[12px] text-blue-900 ">
                Please fill out this field
              </p>
            )}
            {
              //!-------server errors -----

              addEditParagraphErrors &&
                addEditParagraphErrors?.response?.data?.errors?.question && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {
                      addEditParagraphErrors?.response?.data?.errors
                        ?.question[0]
                    }
                  </p>
                )
            }
          </div>
        </>

        <button
          disabled={submitLoading && submitParagraph.flag}
          className=" submit mt-6 !w-1/2 "
          type="submit"
        >
          {submitLoading && submitParagraph.flag ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <p>
              {getInfoSuccess?.data?.paragraph
                ? "Update Paragraphe"
                : "Add Paragraphe"}
            </p>
          )}
        </button>
      </form>
    </section>
  );
}

export default AdminAddEditParagraphe;
